@font-face {
  font-family: "Nexa";
  src: local("Nexa-Light"),
    url("./assets/fonts/nexa-light.woff2") format("woff2"),
    url("./assets/fonts/nexa-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: local("Nexa-Light-Italic"),
    url("./assets/fonts/nexa-light-italic.woff2") format("woff2"),
    url("./assets/fonts/nexa-light-italic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: local("Nexa"),
    url("./assets/fonts/nexa-regular.woff2") format("woff2"),
    url("./assets/fonts/nexa-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: local("Nexa-Italic"),
    url("./assets/fonts/nexa-regular-italic.woff2") format("woff2"),
    url("./assets/fonts/nexa-regular-italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: local("Nexa-Bold"),
    url("./assets/fonts/nexa-bold.woff2") format("woff2"),
    url("./assets/fonts/nexa-bold.woff") format("woff");
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: "Nexa";
  src: local("Nexa-Bold-Italic"),
    url("./assets/fonts/nexa-bold-italic.woff2") format("woff2"),
    url("./assets/fonts/nexa-bold-italic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: local("Nexa-XBold"),
    url("./assets/fonts/nexa-xbold.woff2") format("woff2"),
    url("./assets/fonts/nexa-xbold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: local("Nexa-XBold-Italic"),
    url("./assets/fonts/nexa-xbold-italic.woff2") format("woff2"),
    url("./assets/fonts/nexa-xbold-italic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: local("Nexa-Heavy"),
    url("./assets/fonts/nexa-heavy.woff2") format("woff2"),
    url("./assets/fonts/nexa-heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: local("Nexa-Heavy-Italic"),
    url("./assets/fonts/nexa-heavy-italic.woff2") format("woff2"),
    url("./assets/fonts/nexa-heavy-italic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

:root {
  --header-height: 6.875rem;
  --footer-height: 2.8125rem;
  --copyright-height: 0.9375rem;
  --sidebar-title-height: 2.8125rem;
  --login-register-container-height: 100%;
  --opmodal-box-width: 100%;

  --white: #fff;
  --gray: #888;
  --gray2: rgb(150, 150, 150);
  --gray-other: #808080;
  --gray-light: #eee;
  --color-1: #00659d;
  --color-light-1: #e6f0f5;
  --color-light-2: #88a8c5;
  --color-light-3: #66a3c4;
  --color-light-4: #417c9c;
  --color-light-5: #3384b1;
  --color-light-6: #3283b0;
  --color-red: #fa3335;
  --color-amber: #ffb43c;
  --color-amber-2: #f9d544;
  --color-amber-light: #fbe68f;
  --color-green: #4db125;
  --color-green-2: #3ea676;
  --color-green-3: #2fa400;
  --color-green-light: #7ac2a3;
  --color-green-light-2: #d9ede4;

  /* custom style for react-toastify */
  --toastify-toast-width: 25.9375rem;
}

img {
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

* {
  font-family: "Nexa", ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  /* background-color: var(--white); */
  padding: 0;
  margin: 0;
}

html,
a,
body,
p,
span {
  color: var(--color-1);
}

*:focus {
  outline: none;
}

body.no-scroll {
  overflow: hidden;
}

/* Default */
/* h1 is   32px   (2em)
h2 is   24px (1.5em)
h3 is 20.8px (1.3em)
h4 is   16px   (1em)
h5 is 12.8px (0.8em)
h6 is 11.2px (0.7em) */

/* Custom */
/* h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.4rem;
}

h4 {
  font-size: 1.3rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.8rem;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

input[type="text"],
input[type="password"],
input[type="email"] {
  outline: none;
}

button,
a {
  cursor: pointer;
  text-decoration: none;
}

button:active,
a:active {
  color: inherit;
  text-decoration: none;
}

.bounce:active {
  animation: bounce 0.3s infinite alternate;
}

.pointer {
  cursor: pointer;
}

@keyframes bounce {
  from {
    transform: scale(0.95);
  }

  to {
    transform: scale(1);
  }
}

.bold {
  font-family: "Nexa";
  font-weight: bold;
}

.text-black {
  color: #000;
}

.op-back-btn {
  width: 7.5rem;
  background-color: #fff;
  border: 0.1875rem solid #ddd;
  color: #888;
  outline: none;
}

.op-back-btn:hover,
.op-back-btn:active {
  background-color: #ddd;
  border: 0.1875rem solid #ddd;
  color: #fff;
}

.op-text {
  font-size: 0.8rem;
}

.text-green {
  color: var(--color-green);
}

.text-amber {
  color: var(--color-amber);
}

.text-red {
  color: var(--color-red) !important;
}

.text-gray {
  color: var(--gray) !important;
}

.border-box {
  box-sizing: border-box;
}

#ed-root {
  position: relative;
  display: grid;
  width: 100%;
  box-sizing: border-box;
  grid-template-columns: 17.1875rem 1fr;
  grid-template-rows: var(--header-height) 1fr;
  grid-template-areas:
    "header header"
    "sidebar main"
    "sidebar footer";
}

.bg-img {
  background-image: url("./assets/images/home/background.jpg");
  background-position-y: bottom;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: 90%;
}

.bg-img-mobile {
  background-image: url("./assets/images/home/background-mobile.jpg");
  background-size: 60%;
  background-position-y: bottom;
  background-position-x: right;
  background-repeat: no-repeat;
}

.header {
  grid-area: header;
  height: var(--header-height);
  box-sizing: border-box;
  border-bottom: 0.125rem solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  position: relative;
  background-color: #fff;
}

.main {
  grid-area: main;
  /* height: calc(100vh - var(--header-height) + var(--footer-height)) !important; */
  box-sizing: border-box;
}

.main.ref-view-main {
  /* max-height: calc(100vh - var(--header-height) + var(--footer-height)) !important; */
  box-sizing: border-box;
}

.main-content {
  box-sizing: border-box;
  min-height: calc(100vh - (var(--header-height) + var(--footer-height)));
  max-height: calc(100vh - (var(--header-height) + var(--footer-height)));
  padding-bottom: 10px;
}

.main-content:has(.ref-sub-reason) {
  padding-bottom: 0;
}

.main-content:has(.ld-main-content) {
  overflow: unset;
}

.sidebar {
  grid-area: sidebar;
  min-height: calc(100vh - (var(--header-height)));
  max-height: 100%;
  box-sizing: border-box;
  border-right: 0.125rem solid #eee;
  padding-top: 1.25rem;
  position: relative;
  color: var(--color-1);

  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  background-color: #fff;
}

.footer {
  grid-area: footer;
  height: var(--footer-height);
  box-sizing: border-box;
  /* border-top: 0.125rem solid #eee; */
  display: flex;
  align-items: center;
}

#ed-root.mz-ref-view .main-content {
  min-height: calc(100vh - (var(--header-height)));
  max-height: calc(100vh - (var(--header-height)));
}

.main-content:has(.ref-view-main-content) {
  min-height: calc(100vh - (var(--header-height)));
  max-height: calc(100vh - (var(--header-height)));
}

#ed-root.mz-ref-view .main-content+.footer {
  position: absolute;
  top: calc(-1 * var(--footer-height));
  z-index: 1;
}

.main-content:has(.ref-view-main-content)+.footer {
  position: absolute;
  top: calc(-1 * var(--footer-height));
}

/* #ed-root:has(.user-voted)+.footer {
  display: none;
}

#ed-root.user-voted .main-content + .footer {
  display: none;
} */

/* Header Elements */
.left-nav {
  padding-left: 3.125rem;
}

.header-logo {
  display: inline-block;
  height: 4.0625rem;
  width: 10.3125rem;
  cursor: pointer;
}

.center-nav {
  font-size: 1rem;
  color: var(--color-1);
  margin-top: 0.625rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 47rem;
}

.center-nav * {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.center-nav>ul {
  margin: 0;
  position: relative;
  padding: 0;
}

.center-nav>ul>li {
  display: inline-block;
  margin: 0 1.0625rem;
  padding: 0.4375rem 0.5rem;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
}

/* .center-nav .active {
  font-family: "Nexa-XBold";
} */

.center-nav .active:before {
  box-sizing: border-box;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 0.1875rem;
  clip-path: polygon(3% 0%, 97% 0%, 100% 100%, 0% 100%);
  /* margin: 0 1.0625rem; */
  background-color: var(--color-1);
}

.badge-nav {
  position: absolute;
  top: -0.3125rem;
  right: 0.1875rem;
  color: #fff;
  border-radius: 50%;
  font-size: 0.55rem;
  font-family: "Nexa";
  font-weight: 300;
  background-color: var(--color-red);
}

.badge-msg {
  right: 1.5625rem;
}

.right-nav {
  padding-right: 3.125rem;
}

.right-nav .join-btn {
  font-size: 1.05rem;
  border: none;
  border-radius: 0.625rem;
  width: 7.8125rem;
  height: 3.125rem;
  background-color: var(--color-light-3);
  color: var(--white);
}

.right-nav .join-btn:hover {
  background-color: var(--color-1);
}

.right-nav .search-form {
  width: 3rem;
  height: 3rem;
  border: 0.125rem solid #eee;
  border-radius: 1.5625rem;
  font-size: 1.05rem;
  padding-left: 1.5rem;
  padding-right: 1.5625rem;
  font-size: 1rem;
  color: #888;
  position: relative;
  margin-right: 1rem;
  transition: width 0.3s;
}

.right-nav .search-form input {
  display: none;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 1.5625rem;
  font-size: 1.05rem;
  padding-left: 0;
  padding-right: 0;
  font-size: 1rem;
  color: #888;
  z-index: 1;
}

.right-nav .search-form input:focus {
  border: none;
  outline: none;
}

.right-nav .search-form:hover {
  cursor: pointer;
}

.right-nav .search-form.active {
  width: 24.25rem;
  padding-left: 0;
  padding-right: 0 !important;
  text-overflow: ellipsis;
}

.right-nav .search-form.active input {
  display: block;
  padding-left: 2.325rem;
  padding-right: 1.5625rem;
  text-overflow: ellipsis;
}

.right-nav .search-form.active input::placeholder {
  font-size: 0.93rem;
}

/* .right-nav .search-form input {
  width: 3rem;
  height: 3rem;
  padding-left: 1.5rem;
  transition: width 0.2s;
} */

/* .right-nav .search-form.active input {
  width: 24.25rem;
  padding-left: 2.125rem;
  text-overflow: ellipsis;
} */

.right-nav .search-form.active:hover {
  cursor: unset;
}

.right-nav .search-form>div {
  width: 100%;
  height: 100%;
}

.right-nav .search-form .icon {
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1.15rem;
  height: 1.15rem;
  transition: transform 0.2s;
}

.right-nav .search-form.active .icon {
  left: 0.9rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.15rem;
  height: 1.15rem;
}

.modal-search-container {
  min-height: 17.75rem;
  height: 19.75rem;
  max-height: 100%;
  max-width: 50rem !important;
}

.modal-search-container h3 {
  font-family: "Nexa" !important;
  font-weight: 900 !important;
}

.modal-search-form {
  width: 100%;
  height: 3rem;
}

.modal-search-form>div {
  height: 100%;
}

.modal-search-form input[type="text"] {
  height: 100% !important;
  width: 100% !important;
  border: 0.125rem solid #eee;
  border-radius: 1.5625rem;
  font-size: 1.05rem;
  padding-left: 2.5rem;
  padding-right: 1.5625rem;
  font-size: 1rem !important;
  color: #888;
}

.header-prof-btn {
  color: #000;
}

.header-prof-btn:hover,
.header-prof-btn:active,
.header-prof-btn:focus {
  color: #000;
}

.header-right-action-menu {
  position: absolute;
  right: 0;
  top: 4.375rem;
  width: 6.875rem;
  height: auto;
  padding: 0.625rem;
  border: 0.0625rem solid var(--color-1);
  border-radius: 0.625rem;
  background-color: #fff;
}

.header-right-action-menu * {
  margin: 0;
  padding: 0;
}

.header-right-action-menu ul {
  list-style: none;
  text-align: right;
}

.header-right-action-menu ul li>* {
  font-family: "Nexa";
  font-weight: 800;
}

.header-right-action-menu ul li:last-child {
  margin-top: 0.3125rem;
}

.header-right-action-menu ul li:hover {
  cursor: pointer;
  color: var(--color-light-3);
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.header-right-action-menu li>* {
  display: inline-block;
  width: 100%;
  height: auto;
  border-radius: 0.625rem;
  padding: 0.125rem 0.3125rem 0.125rem 0.625rem;
}

.header-right-action-menu button {
  text-align: right;
  border: none;
  background-color: transparent;
  color: var(--color-1);
}

.header-right-action-menu li>*:hover,
.header-right-action-menu li>*:active .header-right-action-menu li>*:focus {
  background-color: var(--color-light-1);
  color: var(--color-1);
}

/* End of Header Elements */

/* Sidebar Elements */
.sidebar .top-section {
  width: 100%;
}

.sidebar h4 {
  padding: 0.625rem 3.125rem 1.25rem 3.125rem;
  color: var(--gray);
  font-size: 0.74rem;
  font-family: "Nexa";
  height: var(--sidebar-title-height);
}

.sidebar ul.cat-list {
  padding: 0;
  margin: 0;
  /* max-height: calc(100vh - (var(--header-height) + var(--copyright-height) + var(--sidebar-title-height) + 3.75rem)); */
  height: calc(100vh - (var(--header-height) + var(--copyright-height) + var(--sidebar-title-height) + 3.75rem));
  overflow: hidden overlay;
}

.sidebar ul>li {
  display: block;
  justify-content: flex-start;
  color: var(--color-1);
  /* margin-top: 0.375rem; */
}

.sidebar a {
  display: flex;
  align-items: center;
  padding: 0.5rem 3.125rem;
  font-size: 0.85rem;
}

.sidebar ul>li:hover {
  background-color: var(--color-light-1);
}

.sidebar img {
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 0.625rem;
  background-color: inherit;
}

.sidebar span {
  background-color: inherit;
}

.sidebar .active {
  background-color: var(--color-light-1);
}

.sidebar .active span {
  font-family: "Nexa";
  font-weight: 800;
}

.copyright {
  width: 100%;
  margin: 1.25rem 0;
  text-align: center;
  /* position: absolute;
  bottom: 0; */
  height: var(--copyright-height);
  color: var(--color-light-2);
  font-family: "Nexa";
  font-weight: 300;
  font-size: 0.6rem;
}

/* End of Sidebar Elements */

/* Footer Elements */

.footer h5 {
  font-family: "Nexa";
  font-weight: 700;
  font-size: 0.65rem;
}

.footer ul {
  color: var(--color-1);
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  font-size: 0.7rem;
  padding: 0;
  margin: 0;
}

.footer li {
  margin: 0 0.625rem;
}

.footer li:first-child {
  margin: 0 0.625rem 0 1.250rem;
}

.footer li:hover {
  text-decoration: underline;
}

/* End of Footer Elements */

/* Main Elements */
.main .top {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3.125rem;
}

.main h1 {
  color: #000;
  font-family: "Nexa";
  font-weight: 900;
  font-size: 1.8rem;
}

.ref-group-opt::selection {
  user-select: none;
}

.search-form div {
  position: relative;
}

.search-form .icon {
  position: absolute;
  left: 1.25rem;
  top: 0.6875rem;
  width: 1.25rem;
  height: 1.25rem;
}

.modal-search-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
}

.search-form input {
  width: 31.25rem;
  height: 2.5rem;
  border: 0.125rem solid #eee;
  border-radius: 1.5625rem;
  font-size: 1.05rem;
  padding-left: 3.125rem;
  padding-right: 1.5625rem;
  font-size: 1rem;
  color: #888;
}

#modal_search::placeholder {
  color: #bbb;
}

.search-form input::placeholder {
  color: #bbb;
  font-size: 1rem;
}

.search-form input:focus {
  border: 0.125rem solid #2684FF;
}

.main .center {
  display: flex;
  justify-content: space-evenly;
}

.main .center.ref-main {
  overflow-y: scroll;
}

.main .center .card,
.card {
  background-color: rgba(255, 255, 255, 0.7);
}

.gp-header-text {
  color: #000;
  font-family: "Nexa";
  font-weight: 700;
}

.gp-header-btn {
  min-width: 1.5625rem;
  min-height: 1.5625rem;
  width: 1.5625rem;
  height: 1.5625rem;
  border-radius: 0.4375rem;
  padding: 0.3125rem;
  box-sizing: border-box;
  background-color: var(--color-light-3);
}

.gp-header-btn img {
  width: 100%;
  height: 100%;
}

.no-ref-suggested {
  height: 12rem;
}

.no-ref-suggested.lg {
  height: 25rem;
  padding-right: 1rem;
}

.gp-header-btn:hover,
.gp-header-btn:active {
  background-color: var(--color-1);
}

.gp-ref {
  padding: 0.625rem 0.3125rem 0.625rem 0.625rem;
  border-radius: 0.9375rem;
}

.gp-ref:hover {
  background-color: var(--color-light-1);
}

.search-ref-title {
  padding: 0 !important;
}

.search-ref-title a {
  padding: 0.625rem;
  font-family: "Nexa";
}

.search-ref-title:hover a {
  background-color: var(--color-light-1);
  border-radius: 0.625rem;
  color: var(--color-1);
  font-family: "Nexa";
  font-weight: 700;
  cursor: pointer;
}

.result-container.my-ref .search-ref-title {
  /* padding-right: 0.625rem !important; */
}

.timer-red {
  color: var(--color-red);
}

.timer-amber {
  color: var(--color-amber);
}

.timer-green {
  color: var(--color-green);
}

.timer-grey {
  color: var(--gray2);
}

.gp-highlight {
  background-color: var(--color-light-1);
}

.gp-ref-title {
  font-size: 0.85rem;
  margin: 0;
}

.gp-ref:hover .gp-ref-title {
  font-family: "Nexa";
  font-weight: 700;
}

.gp-ref-timer {
  margin: 0;
  flex-basis: 3.5625rem;
  flex-shrink: 0;
}

.gp-ref-timer-text {
  padding-top: 0.0625rem;
  font-size: 0.55rem;
}

.gp-ref-timer-icon {
  width: 0.9375rem;
  height: 0.9375rem;
}

.mybadgesnoneuser .card-body h5 {
  font-size: 0.9375rem;
  font-weight: bold;
}

.mybadgesnoneuser .card-body .text {
  font-size: 0.75rem;
}

.badge-card {
  background-image: url("./assets/images/profile/my-badges-medal.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 1.25rem !important;
  position: relative;
}

.badge-card.disabled {
  cursor: not-allowed;
}

.badge-card.disabled:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ccccccb0;
}

.profile-detials-controls button {
  background: #66a3c4;
  color: white;
  min-width: 1.25rem;
  font-size: 0.75rem;
  height: 1.25rem;
  border-radius: 0.4375rem;
}

.profile-detials-controls button:hover {
  background-color: #4e7488;
  transition: 0.3s ease;
}

.OPModal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.651);
  height: 100%;
  z-index: 9999;
  /* padding: 0 2rem;
  box-sizing: border-box; */
}

.OPModalBox {
  min-height: 18.75rem;
  width: 48.4375rem;
  left: 0;
  right: 0;
  background-color: white;
  width: 100%;
  max-width: var(--opmodal-box-width);
  top: 50%;
  z-index: 200;
  border-radius: 0.9375rem;
  /* border-radius: 1.875rem; */
}

.auth-container {
  width: 100%;
  max-width: 54.6875rem;
  /* height: 26.25rem; */
}

.welcome {
  width: 100%;
  max-width: 52.8125rem;
  height: 33rem;
}

.review {
  width: 100%;
  max-width: 54.6875rem;
  max-height: 33.125rem;
}

.update-voting,
.delete-ref {
  width: 100%;
  max-width: 47.375rem;
  max-height: 31.875rem;
}

.fblack {
  color: #161616;
}

.fGray {
  color: #b3b3b3;
}

.fcolo1 {
  color: var(--color-1);
}

.fbold {
  font-family: "Nexa";
  font-weight: bold;
}

.FS25 {
  font-size: 1.5625rem !important;
}

.FS18 {
  font-size: 1.125rem !important;
}

.FS14 {
  font-size: 0.875rem !important;
}

.FS13 {
  font-size: 0.8125rem !important;
}

.FS12 {
  font-size: 0.75rem;
}

.FS10 {
  font-size: 0.625rem;
}

.gold-rounded {
  color: #fff;
  background-color: #DAAB3B;
  border: 0.0625rem solid #DAAB3B;
  border-radius: 0.9375rem;
}

.gray-rounded {
  color: #fff;
  background-color: #B6B5B5;
  border: 0.0625rem solid #B6B5B5;
  border-radius: 0.9375rem;
}

.brown-rounded {
  color: #fff;
  background-color: #8D4417;
  border: 0.0625rem solid #8D4417;
  border-radius: 0.9375rem;
}

.OPGroupButton button {
  padding: 0.625rem 1.25rem;
  border: 0;
  width: 7.0625rem;
  border-radius: 0.9375rem;
}

.default-button {
  background-color: var(--color-light-3);
  color: white;
}

.default-button:hover {
  background-color: var(--color-light-4);
  transition: 0.3s ease;
}

a.default-button:hover {
  color: white;
}

/* End of Main Elements */

/* Modal */
.customModal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.24);
  transition: 0.3s ease-in;
  opacity: 0;
  pointer-events: none;
}

.customModal.show {
  opacity: 1;
  transition: 0.2s ease-in;
  pointer-events: unset;
}

.modal-container {
  background-color: white;
  max-width: 52.8125rem !important;
  max-height: 33.4375rem !important;
  box-sizing: border-box;
  border-radius: 1.5625rem;
}

.custom-modal-header {
  padding-bottom: 1.5rem;
}

.custom-modal-header>h3 {
  font-family: "Nexa", Nexa, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  color: #000;
  font-weight: bold;
  font-size: 1.8rem;
}

.exit-container {
  display: inline-block;
  width: 1.875rem;
  height: 1.875rem;
}

.exit-btn {
  outline: none;
  right: 1.25rem;
  top: 1.875rem;
}

.exit-btn:active {
  border: none;
}

.border-left-divider {
  border-left: 0.0625rem solid #ddd;
}

.border-bottom-divider {
  border-bottom: none;
}

.login-register input[type="text"],
.login-register input[type="email"],
.login-register input[type="password"] {
  width: 20.125rem;
  height: 1.5625rem;
  font-size: 0.7rem;
}

.login-register input[type="text"]:focus,
.login-register input[type="email"]:focus,
.login-register input[type="password"]:focus {
  width: 20.125rem;
  height: 1.5625rem;
  font-size: 0.7rem;
}

.login-register .input-text,
.cr-ref .input-text {
  font-size: 0.7rem;
}


.reset-password input[type="password"] {
  width: 100%;
  height: 1.5625rem;
  font-size: 0.7rem;
}

.input-error {
  font-size: 0.7rem;
}

.op-btn {
  border: none;
  color: #fff;
  background-color: var(--color-light-5);
  font-family: "Nexa";
  font-weight: 300;
  padding: 0.9375rem 1.875rem;
  border-radius: 0.625rem;
  font-size: 1rem;
}

.back-btn {
  width: 7.5rem;
  background-color: #fff;
  border: 0.1875rem solid #ddd;
  font-family: "Nexa";
  font-weight: 300;
  color: #ddd;
  outline: none;
  border-radius: 0.625rem;
}

.back-btn:hover,
.back-btn:focus,
.back-btn:active {
  background-color: #ddd;
  border: 0.1875rem solid #ddd;
  color: #fff;
}

.op-btn:hover,
.op-btn:focus,
.op-btn:active {
  color: #fff;
  background-color: var(--color-1);
}

.op-load-btn {
  display: block;
  width: 100%;
  border: none;
  color: var(--color-light-2);
  background-color: var(--color-light-1);
  font-family: "Nexa";
  font-weight: 700;
  font-size: 0.8rem;
  padding: 0.625rem 0;
  border-radius: 0.625rem;
}

.op-load-btn:hover,
.op-load-btn:focus,
.op-load-btn:active {
  color: var(--color-1);
}

.not-allowed {
  cursor: not-allowed !important;
}

/* .suggestion-btn {
  height: 3.125rem;
}

.login-btn {
  width: 7.8125rem;
  height: 3.4375rem;
}

.register-btn {
  width: 7.8125rem;
  height: 3.4375rem;
}

.confirm-btn {
  width: 7.8125rem;
  height: 3.4375rem;
}

.delete-btn {
  padding: 0.9375rem 1.875rem;
} */

.suggestion-container {
  min-height: 37.625rem;
  border-radius: 1.875rem;
  padding-right: 1.5rem;
}

.main-home .suggestion-container {
  height: 100%;
}

.suggestion-container {
  min-height: 20.1875rem;
  padding-right: 1rem;
}

.modal-sub-header {
  font-family: "Nexa";
  font-weight: 700;
}

.auth-text {
  font-family: "Nexa";
  font-size: 0.8rem;
  color: #333;
}

.op-link-btn {
  color: var(--color-1);
  background-color: transparent;
  border: none;
}

.op-link-btn.disabled {
  cursor: not-allowed;
  color: #c0c0c0;
}

.OP-link {
  color: var(--color-1);
}

/* End of Modal */

/* Search Page */
.search-form .icon.icon-xs {
  top: 0.5625rem;
  left: 0.625rem;
  width: 0.625rem;
  height: 0.625rem;
}

.search-form .icon.icon-right.icon-xs {
  top: 0.5625rem;
  right: 0.8125rem;
  left: auto;
  width: 0.625rem;
  height: 0.625rem;
}

.search-form .search-input-xs {
  width: 16.875rem;
  height: 1.75rem;
  font-size: 0.7rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  border: 0.125rem solid #ddd;
}

.search-form .search-input-xs::placeholder {
  font-size: 0.7rem;
}

.search-grouping {
  width: 13.125rem;
  height: 1.5625rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.search-grouping li {
  box-shadow: 0 0 0 0.0625rem var(--color-light-5);
  margin: 0 0 0.0625rem 0.0625rem;
  width: 25%;
  cursor: pointer;
  text-align: center;
  padding: 0.125rem 0;
  box-sizing: border-box;
  font-size: 0.9rem;
}

.search-grouping li:first-child {
  border-top-left-radius: 0.4375rem;
  border-bottom-left-radius: 0.4375rem;
}

.search-grouping li:last-child {
  border-top-right-radius: 0.4375rem;
  border-bottom-right-radius: 0.4375rem;
}

.search-grouping li.active {
  background-color: var(--color-1);
}

.search-sort-select {
  color: var(--color-1);
  font-size: 0.7rem;
  font-family: "Nexa";
  font-weight: 700;
  padding: 0.3125rem 2.5rem 0.3125rem 0.625rem;
  appearance: none;
  border: 0.0625rem solid var(--color-1);
  border-radius: 0.4375rem;
  background: url("./assets/images/others/caret-blue.svg") no-repeat 90% 55%;
  background-size: 0.625rem;
  cursor: pointer;
}

.search-sort-select.select-lg {
  background: url("./assets/images/others/caret-blue.svg") no-repeat 95% 55%;
  background-size: 0.625rem;
}

.result-container {
  font-size: 0.85rem;
}


.result-container:not(.my-ref) .result-header div.col-2:is(:nth-child(1), :nth-child(2)),
.result-container:not(.my-ref) .result-body div.col-2:is(:nth-child(1), :nth-child(2)) {
  width: 12.66666667%;
}

.result-container:not(.my-ref) .result-header div.col-1:is(:last-child),
.result-container:not(.my-ref) .result-body div.col-1:is(:last-child) {
  width: 5.66666667%;
}

.result-container:not(.my-ref) .result-header div.col-2.result-text,
.result-container:not(.my-ref) .result-body div.col-2.result-text {
  width: 20.66666667%;
}

.result-container:not(.my-ref) .result-header div.col-5,
.result-container:not(.my-ref) .result-body div.col-5.search-ref-title {
  width: 48.3333333333%;
}

.p-table-item {
  padding: 0.625rem !important;
}

.px-table-item {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}

.py-table-item {
  padding-top: 0.625rem 0 !important;
  padding-bottom: 0.625rem 0 !important;
}

.pt-table-item {
  padding-top: 0.625rem !important;
}

.result-header>div {
  padding: 0;
}

.pe-result-header {
  padding-right: 1.375rem;
}

.my-ref .result-header .col-1:first-child,
.my-ref .result-body .col-1:first-child {
  /* width: 7.5555555%; */
  width: 6.5555555%;
}

.my-ref .result-header .col-2,
.my-ref .result-body .col-2 {
  /* width: 12.66666667%; */
  width: 10.66666667%;
}

.my-ref .result-header .col-1:not(:first-child, :last-child),
.my-ref .result-body .col-1:not(:first-child, :last-child) {
  width: 14.33333333%;
}

.my-ref .result-header .col-1:last-child,
.my-ref .result-body .col-1:last-child {
  width: 5.33333333%;
}

.my-ref .result-header .col-4,
.my-ref .result-body .col-4 {
  /* width: 34.11111111%; */
  width: 38.11111111%;
}

/* .my-ref .result-header .ref-user-vote {
  padding-left: 0 !important;
  padding-right: 0 !important;
} */

.result-container .result-text {
  box-sizing: border-box;
  /* padding-left: 2.5rem; */
}

.result-header div {
  font-family: "Nexa";
  font-weight: 700;
  color: #000;
}

.result-header .sort-btn {
  font-family: "Nexa";
  font-weight: 700;
}

/* .result-body {
  max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 13.9375rem));
} */

.result-body .lds-dual-ring {
  width: 12.5rem;
  height: 6.25rem;
}

.result-body .result-loader .lds-dual-ring {
  width: 5rem;
  height: 2.8125rem;
}

.result-body .result-loader .lds-dual-ring::after {
  width: 2.8125rem;
  height: 2.8125rem;
  border-color: var(--gray) transparent gray transparent;
}

.statistic-group-card .lds-dual-ring::after {
  width: 3.125rem;
  height: 3.125rem;
  border: 0.625rem solid var(--color-1);
  border-color: var(--color-1) transparent var(--color-1) transparent;
}

.result-container.my-ref .result-text {
  padding-left: 0;
}

.result-container.my-ref .result-body {
  /* max-height: 21.25rem; */
}

.result-container.my-ref .result-header>div,
.result-container.my-ref .result-body>div.row>div {
  /* padding: 0.625rem 1.625rem 0.625rem 0.625rem; */
  padding: 0.625rem 0 0 0;
}

.result-body .lds-dual-ring::after {
  width: 3.75rem;
  height: 3.75rem;
  border: 0.625rem solid var(--color-1);
  border-color: var(--color-1) transparent var(--color-1) transparent;
}

.result-body,
.cr-ref {
  overflow-y: scroll;
  overflow-x: hidden;
}

/* width */
#consent-root .consent-content::-webkit-scrollbar,
.main .center.ref-main::-webkit-scrollbar,
.main-content::-webkit-scrollbar,
.suggestions-list::-webkit-scrollbar,
.cu-left::-webkit-scrollbar,
.about-us-content::-webkit-scrollbar,
.ref-view .ref-view-body::-webkit-scrollbar,
/* .ld-main-content::-webkit-scrollbar, */
.ld-row::-webkit-scrollbar,
.msgs-container::-webkit-scrollbar,
.sidebar ul.cat-list::-webkit-scrollbar,
.OPselectBox .drop::-webkit-scrollbar,
#option-reason::-webkit-scrollbar,
ul.react-datepicker__time-list::-webkit-scrollbar,
.result-body::-webkit-scrollbar,
.message-quick-box::-webkit-scrollbar,
.cr-ref::-webkit-scrollbar,
.select__menu-list::-webkit-scrollbar,
.ref-res-bd::-webkit-scrollbar,
.main .center.ref-main::-webkit-scrollbar {
  width: 0.375rem;
}

/* Track */
#consent-root .consent-content::-webkit-scrollbar-track,
.main .center.ref-main::-webkit-scrollbar-track,
.main-content::-webkit-scrollbar-track,
.suggestions-list::-webkit-scrollbar-track,
.cu-left::-webkit-scrollbar-track,
.about-us-content::-webkit-scrollbar-track,
.ref-view .ref-view-body::-webkit-scrollbar-track,
/* .ld-main-content::-webkit-scrollbar-track, */
.ld-row::-webkit-scrollbar-track,
.msgs-container::-webkit-scrollbar-track,
.sidebar ul.cat-list::-webkit-scrollbar-track,
.OPselectBox .drop::-webkit-scrollbar-track,
#option-reason::-webkit-scrollbar-track,
ul.react-datepicker__time-list::-webkit-scrollbar-track,
.result-body::-webkit-scrollbar-track,
.message-quick-box::-webkit-scrollbar-track,
.cr-ref::-webkit-scrollbar-track,
.select__menu-list::-webkit-scrollbar-track,
.ref-res-bd::-webkit-scrollbar-track,
.main .center.ref-main::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
#consent-root .consent-content::-webkit-scrollbar-thumb,
.main .center.ref-main::-webkit-scrollbar-thumb,
.main-content::-webkit-scrollbar-thumb,
.suggestions-list::-webkit-scrollbar-thumb,
.cu-left::-webkit-scrollbar-thumb,
.about-us-content::-webkit-scrollbar-thumb,
.ref-view .ref-view-body::-webkit-scrollbar-thumb,
/* .ld-main-content::-webkit-scrollbar-thumb, */
.ld-row::-webkit-scrollbar-thumb,
.msgs-container::-webkit-scrollbar-thumb,
.sidebar ul.cat-list::-webkit-scrollbar-thumb,
.OPselectBox .drop::-webkit-scrollbar-thumb,
#option-reason::-webkit-scrollbar-thumb,
ul.react-datepicker__time-list::-webkit-scrollbar-thumb,
.result-body::-webkit-scrollbar-thumb,
.message-quick-box::-webkit-scrollbar-thumb,
.cr-ref::-webkit-scrollbar-thumb,
.select__menu-list::-webkit-scrollbar-thumb,
.ref-res-bd::-webkit-scrollbar-thumb {
  background: #ddd;
  border: 0.0625rem solid transparent;
  border-radius: 0.1875rem;
  background-clip: padding-box;
}

/* Handle on hover */
#consent-root .consent-content::-webkit-scrollbar-thumb:hover,
.main .center.ref-main::-webkit-scrollbar-thumb:hover,
.main-content::-webkit-scrollbar-thumb:hover,
.suggestions-list::-webkit-scrollbar-thumb:hover,
.cu-left::-webkit-scrollbar-thumb:hover,
.about-us-content::-webkit-scrollbar-thumb:hover,
.ref-view .ref-view-body::-webkit-scrollbar-thumb:hover,
/* .ld-main-content::-webkit-scrollbar-thumb:hover, */
.ld-row::-webkit-scrollbar-thumb:hover,
.msgs-container::-webkit-scrollbar-thumb:hover,
.sidebar ul.cat-list::-webkit-scrollbar-thumb:hover,
.OPselectBox .drop::-webkit-scrollbar-thumb:hover,
#option-reason::-webkit-scrollbar-thumb:hover,
ul.react-datepicker__time-list::-webkit-scrollbar-thumb:hover,
.result-body::-webkit-scrollbar-thumb:hover,
.message-quick-box::-webkit-scrollbar-thumb:hover,
.cr-ref::-webkit-scrollbar-thumb:hover,
.select__menu-list::-webkit-scrollbar-thumb:hover,
.ref-res-bd::-webkit-scrollbar-thumb:hover {
  background: #ccc;
  border: 0.0625rem solid transparent;
  border-radius: 0.1875rem;
  background-clip: padding-box;
}

.ref-view .ref-view-body {
  overflow: hidden scroll;
}

.ref-view .ref-view-body.past {
  overflow: hidden;
}

.result-body>div.row>div {
  padding: 0.625rem 0 0 0;
  box-sizing: border-box;
}

.result-container:is(.my-ref) .result-header.row .col-1.ref-user-vote,
.result-container:is(.my-ref) .result-body .row .col-1.ref-user-vote,
.result-container:is(.my-ref) .result-header.row .col-1.result-text,
.result-container:is(.my-ref) .result-body .row .col-1.result-text,
.result-header.row .col-2.result-text,
.result-body .row .col-2.result-text {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.result-container:is(.my-ref) .result-header.row .col-1.result-text,
.result-container:is(.my-ref) .result-body .row .col-1.result-text {
  padding-right: 0.625rem;
}

/* .result-body .active {
  background-color: var(--color-light-1);
  border-radius: 0.625rem;
  font-family: Nexa-Bold;
} */

/* End of Search Page */

/* profile */
.input-with-toggle button {
  right: 0.3125rem;
  top: 0;
  background: transparent;
  height: 100%;
  z-index: 1;
}

/* statistic */
.statistic-single-card {
  background: linear-gradient(to right, #b4ddca, #79aecc);
  padding: 0.0625rem;
  border-radius: 1.25rem;
  height: 100%;
}

.statistic-single-card .card-body {
  background-color: white;
  border-radius: 1.0625rem;
}

.statistic-single-card h5 {
  font-size: 0.75rem;
}

.statistic-single-card .number {
  font-size: 1.4rem;
  background: linear-gradient(to right, #0a6d99, #b2dcc9);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Nexa";
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
}

.card-header.clear-header,
.card-footer.clear-header {
  background-color: transparent;
  border-bottom: 0;
  font-family: "Nexa";
  font-weight: 700;
  font-size: 1.25rem;
  border-top: 0;
}

.selected-box {
  padding: 0.3125rem 0.625rem;
  padding-right: 1.5625rem;
  position: relative;
  min-height: 1.25rem;
  font-size: 0.75rem;
  min-width: 6.25rem;
  border: 0.0625rem solid #ccc;
  border-radius: 0.625rem;
  cursor: pointer;
}

.selected-box .carret {
  position: absolute;
  right: 0.4375rem;
  font-size: 0.9375rem;
  top: 50%;
  transform: translateY(-50%);
}

.OPselectBox {
  min-width: 8rem;
}

.OPselectBox .drop {
  width: 100%;
  background-color: white;
  box-shadow: 0.0625rem 0.0625rem 0.3125rem #ccc;
  z-index: 10;
  border-radius: 0 0 0.3125rem 0.3125rem;
  overflow-x: hidden;
  overflow-y: overlay;
  max-height: 8.75rem;
}

.OPselectBox .drop ul {
  list-style: none;
}

.OPselectBox .drop li {
  padding: 0.625rem;
  font-size: 0.75rem;
  cursor: pointer;
}

.OPselectBox .drop li:hover,
.OPselectBox .drop li.active {
  background-color: var(--color-1);
  color: white;
  transition: 0.3s ease;
}

.mystat-card-history,
.card-transparent {
  background-color: rgb(255 255 255 / 70%);
  border: 0;
}

.OPTable {
  border-bottom: 0 !important;
}

.table.OPTable> :not(caption)>*>* {
  border-bottom: 0;
}

.OPTable thead {
  border: none !important;
  font-size: 0.875rem;
  color: #4d4d4d;
}

.OPTable tbody td {
  font-size: 0.85rem;
}

.mystat-card-history {
  background-color: rgb(255 255 255 / 42%);
}

/* Enf of Profile */

/* Referendum View Page */
.ref-view .ref-view-header>*,
.ref-view .ref-cc h4,
.ref-view .ref-cc a {
  font-size: 0.74rem;
}

.ref-view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hover-category:hover {
  cursor: pointer;
}

.ref-categories {
  box-sizing: border-box;
  width: 100%;
  height: 1.75rem;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.ref-categories.active {
  overflow: visible;
}

.ref-categories ul {
  box-sizing: border-box;
  position: relative;
  top: -1.25rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 0.625rem;
  /* padding: 0.3125rem 2.5rem 0.3125rem 0.625rem; */
  padding: 0.3125rem 1.5rem 0.3125rem 0.625rem;
  margin-top: 0.0625rem;
  margin-left: 0.0625rem;
  background-color: transparent;
}


.ref-categories.active ul {
  box-sizing: border-box;
  background-color: #fff;
  border: 0.0625rem solid var(--color-1);
  padding: 0.25rem 1.4375rem 0.25rem 0.5625rem;
}

.ref-categories ul:hover {
  cursor: pointer;
}

.ref-categories.ref-categories-solo ul {
  padding: 0.3125rem 0 0.3125rem 0.625rem;
}

.ref-categories ul li {
  margin-top: 0.4375rem;
  box-sizing: border-box;
}

.ref-categories ul li:first-child {
  margin-top: 0;
  position: relative;
}

.ref-categories ul li:first-child::after {
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  background-image: url("./assets/images/others/caret-blue.svg");
  background-repeat: no-repeat;
  position: absolute;
  right: -1rem;
  top: 0.6125rem;
}

.ref-categories.active ul li:first-child::after {
  background-image: url("./assets/images/others/caret-blue.svg");
  transform: rotate(180deg);
  top: 0.3062rem;
}

.ref-categories ul li a {
  color: var(--color-1);
  font-family: "Nexa";
  box-sizing: border-box;
}

.ref-categories.ref-categories-solo ul li:first-child::after {
  content: none;
}

.ref-categories.ref-categories-solo ul:hover {
  cursor: text;
}

.ref-categories a {
  font-family: "Nexa";
  font-weight: 700;
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

.ref-categories.ref-categories-solo a {
  user-select: text;
  /* supported by Chrome and Opera */
  -webkit-user-select: text;
  /* Safari */
  -khtml-user-select: text;
  /* Konqueror HTML */
  -moz-user-select: text;
  /* Firefox */
  -ms-user-select: text;
  /* Internet Explorer/Edge */
}

.ref-category.suggested {
  position: absolute;
  bottom: -1.5625rem;
}

.ref-categories button {
  position: absolute;
  right: 1.25rem;
  top: -0.25rem;
  background-color: transparent !important;
}

.ref-categories button img {
  width: 0.625rem;
  height: 0.625rem;
}

.ref-view .ref-view-header button {
  background-color: var(--color-amber-light);
  color: #fff;
}

.ref-view .ref-view-header button::selection {
  user-select: none;
}

.ref-view .ref-view-header button img::selection {
  user-select: none;
}

.ref-view .ref-view-header button,
.ref-view .ref-view-header .ref-country,
.ref-categories ul li {
  border-radius: 0.625rem;
  border: none;
}

.ref-view .ref-view-header button:hover,
.ref-view .ref-view-header button.active {
  background-color: var(--color-amber-2);
  color: #fff;
}

.ref-categories ul li:hover a,
.ref-categories ul li:active a {
  font-family: "Nexa";
  font-weight: 700;
  /* text-decoration: underline; */
}

.ref-categories ul li:hover,
.ref-categories ul li:active,
.ref-view .ref-view-header .ref-country:hover,
.ref-view .ref-view-header .ref-country:active {
  font-family: "Nexa";
  font-weight: 700;
  background-color: var(--color-light-1);
  color: var(--color-1);
  cursor: pointer;
}

.ref-view .ref-view-header .icon {
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.1875rem;
}

.ref-view .ref-view-header .menu-icon {
  border-radius: 50%;
  background-color: var(--color-light-3);
}

.ref-view .ref-view-header .menu-icon:hover,
.ref-view .ref-view-header .menu-icon.active {
  background-color: var(--color-1);
  color: #fff;
}

/* .ref-view .ref-view-body {
  height: 36.25rem;
  max-height: 100%;
} */

.ref-view .ref-view-body .ref-title {
  font-family: "Nexa";
  font-weight: 700;
}

.ref-view .ref-title-icon {
  font-size: 2.5rem;
}

.ref-voting .ref-choices {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5625rem;
}

.ref-choice {
  min-width: 10rem;
}

.ref-voting .ref-choices .ref-choice .ref-choice-label {
  background-color: #fff;
  border: 0.125rem solid var(--color-green-light);
  color: var(--color-green-light);
  border-radius: 0.625rem;
  width: 100%;
}

.ref-voting .ref-choices .ref-choice .ref-choice-label:not(.past) {
  cursor: pointer;
}

.ref-voting .ref-choices.preview .ref-choice .ref-choice-label {
  cursor: auto;
}

.ref-voting .ref-choices .ref-choice .ref-choice-label:not(.past):hover,
.ref-voting .ref-choices .ref-choice .ref-choice-label.active,
.ref-voting .ref-choices.preview .ref-choice .ref-choice-label:not(.past):hover.active {
  background-color: var(--color-green-light-2);
  border: 0.125rem solid var(--color-green-2);
  color: var(--color-green-2);
}

.ref-voting .ref-choices.preview .ref-choice .ref-choice-label:hover {
  background-color: #fff;
  border: 0.125rem solid var(--color-green-light);
  color: var(--color-green-light);
}

.ref-voting .ref-choices.preview .ref-choice .ref-choice-label:hover.active {
  background-color: var(--color-green-light-2);
  border: 0.125rem solid var(--color-green-2);
  color: var(--color-green-2);
}

.ref-voting .ref-reason textarea {
  border: 0.125rem solid #eee;
  border-radius: 0.625rem;
  resize: none;
  font-size: 0.8rem;
  padding: 0.625rem;
}

.submit-btn {
  border-radius: 0.625rem;
  border: none;
  color: #fff;
  /* width: 9.375rem; */
  height: 3.4375rem;
}

.ref-sub-reason {
  padding-top: 9.5rem;
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  flex-direction: column-reverse;
}

.reset-logout {
  width: 100%;
}

.ref-sub-reason-title {
  margin-top: 1.25rem;
  font-family: "Nexa";
  font-weight: 700;
  font-size: 1.3rem;
}

.ref-sub-reason-text {
  font-size: 0.7rem;
  font-style: italic;
  height: 9.375rem;
  overflow: hidden;
  color: #333;
  /* font-family: Nexa-Light; */
  text-overflow: ellipsis;
}

.ref-vote-change {
  font-size: 0.55rem;
  color: #888;
}

.ref-vote-change button {
  background-color: transparent;
  display: inline;
  border: none;
  color: var(--color-light-3);
}

.ref-vote-change button:hover {
  color: var(--color-1);
}

.card-footer-custom {
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 0 0 0.95rem 0;
  margin: 0;
}

.ref-res .ref-res-col {
  margin-top: 0;
  border: 0.0625rem solid var(--gray-other);
  border-radius: 1.5625rem;
  /* height: calc(100% - 1.25rem) */
}

.ref-view .ref-res-title {
  font-family: "Nexa";
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0.3125rem;
}

.ref-view .ref-res-title-icon {
  font-size: 1.5rem;
}

.ref-res-tag {
  background-color: var(--gray-other);
  color: #fff;
  width: 11.25rem;
  padding: 0.0625rem 0;
  font-size: 1.2rem;
  font-style: italic;
  text-align: center;
}

.ref-res-bd {
  /* max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 1rem)); */
  /* max-height: 18rem; */
  min-height: 100%;

  overflow-x: hidden;
}

.ref-res-bd-ld {
  height: 18.75rem;
}

.ref-res-bd-ld .ld-text {
  margin-top: 1.875rem;
}

.ref-res-bd-ld .lds-dual-ring::after {
  width: 3.75rem;
  height: 3.75rem;
  border: 0.625rem solid var(--color-1);
  border-color: var(--color-1) transparent var(--color-1) transparent;
}

.ref-res-status {
  gap: 1rem;
}

.ref-res-status-votes {
  min-width: 8.125rem;
  max-width: 8.125rem;
  min-height: 8.125rem;
  max-height: 8.125rem;
  border-radius: 50%;
  margin-right: 1.875rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: var(--gray-other);
}

.ref-res-status-votes .ref-res-percentage {
  font-size: 3rem;
  font-family: "Nexa";
  font-weight: 800;
}

.ref-res-status-votes .ref-res-votes {
  font-size: 0.75rem;
  font-family: "Nexa";
  font-weight: 300;
}

.ref-res-status-label {
  width: calc(100% - (8.125rem + 2.875rem));
  max-width: calc(100% - (8.125rem + 2.875rem));
}

.ref-res-option {
  margin-left: 0.625rem;
  color: var(--gray-other);
  width: calc(100% - 1rem);
  white-space: pre-line;
}

.ref-res .ref-res-reasons-header,
.ref-res .ref-res-reasons-header>h5 {
  font-size: 0.75rem;
  font-family: "Nexa";
  font-weight: 700;
}

.ref-res .ref-res-reasons-header>div {
  width: 4.0625rem;
}

.ref-res .top-reason {
  padding: 0.125rem 0;
  gap: 1.875rem;
}

.ref-res .reason-ctr {
  min-width: 4.0625rem;
  padding: 0.125rem 0.3125rem;
}

.ref-res .reason-ctr h6 {
  font-size: 0.75rem;
}

.ref-res .top-reason .reason {
  font-size: 0.85rem;
}

.ref-res .top-reason .reason.nr {
  padding: 0.3125rem 0 !important;
}

.ref-res .insight-btn {
  background-color: transparent;
  border: none;
}

.ref-res .insight-btn img {
  margin-top: -0.375rem;
  width: 0.9375rem;
  height: 0.9375rem;
}

.ref-res-col.winner .ref-res-tag {
  background-color: var(--color-green-3);
}

.ref-res-col.winner .ref-res-status-votes {
  border: 0.5rem solid var(--color-green-3);
  color: var(--color-green-2);
}

.ref-res-col.winner .ref-res-option {
  color: var(--color-green-2);
}

.ref-res-col.winner .ref-res-percentage {
  font-size: 2rem;
}

.sc-container,
.flag-container,
.flag-container textarea {
  font-size: 0.8rem;
}

.ref-action {
  position: relative;
}

.ref-action>button {
  position: relative;
}

.ref-actions>button:first-child::after {
  content: "Review";
  padding-right: 1rem;
  position: relative;
  top: 1px;
}

.ref-actions>button:nth-child(2)::after {
  content: "Follow";
  position: relative;
  padding-right: 1rem;
  top: 1px;
}

.ref-actions>button.active:nth-child(2)::after {
  content: "Followed";
  position: relative;
  padding-right: 1rem;
  top: 1px;
}

.ref-action .ref-action-menu {
  position: absolute;
  right: 0.625rem;
  top: 1.875rem;
  height: auto;
  padding: 0.625rem;
  border: 0.0625rem solid var(--color-1);
  border-radius: 0.625rem;
  background-color: var(--white);
}

.ref-action .ref-action-menu.active {
  width: 11.985rem;
}

.ref-action .ref-action-menu.past {
  width: 9.285rem;
}

.hl-border,
.react-datepicker__input-container input {
  border-color: #ddd;
}

.hl-border:focus {
  border: 0.0625rem solid #2684FF !important;
  /* border: 0.0625rem solid var(--color-1) !important; */
  /* border-color: #2684FF !important; */
  box-shadow: 0 0 0 1px #2684FF !important;
  /* box-shadow: 0 0 0 1px var(--color-1) !important; */
}

.hl-border-xs:focus,
.react-datepicker__input-container input:focus {
  border: 0.125rem solid #2684FF !important;
  /* border: 0.0625rem solid var(--color-1) !important; */
  /* border-color: #2684FF !important; */
  /* box-shadow: 0 0 0 1px #2684FF !important; */
  /* box-shadow: 0 0 0 1px var(--color-1) !important; */
}

.ref-action .ref-action-menu * {
  margin: 0;
  padding: 0;
}

.ref-action-menu ul {
  list-style: none;
  text-align: right;

}

.ref-action-menu ul li {
  font-family: "Nexa";
  font-weight: 900;
  padding: 0.3125rem !important;
  color: var(--color-1);
  border-radius: 0.625rem
}

.ref-action-menu ul li:hover {
  cursor: pointer;
  /* color: var(--color-light-3); */
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  background-color: var(--color-light-1);
}

/* End of Referendum View Page */

/* Create Referendum Page */
.cr-ref {
  /* max-height: 38.4375rem; */
  max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 0.625rem + 10.5rem));
}

.cr-ref textarea,
.flag-container textarea {
  resize: none;
  padding: 0.625rem;
}

.cr-ref textarea,
.cr-ref input[type="text"],
.d-select,
.d-select input[type="text"] {
  font-size: 0.7rem;
  border: 0.0625rem solid #ddd;
  border-radius: 0.3125rem;
}

.cr-ref input[type="text"],
.d-select,
.d-select input[type="text"] {
  height: 1.5625rem;
}

.cr-ref .cr-ref-btn {
  box-sizing: border-box;
  border: none;
  border-radius: 0.4375rem;
  background-color: var(--color-light-3);
  padding: 0.0625rem 0.5rem;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5625rem;
}

.cr-ref .cr-ref-btn img {
  position: relative;
  width: 0.625rem;
  height: 0.8125rem;
}

.cr-ref .cr-ref-btn:hover,
.cr-ref .cr-ref-btn:focus,
.cr-ref .cr-ref-btn:active {
  background-color: var(--color-1);
}

.cr-ref-rem-btn {
  margin-top: -0.5rem;
}

.cr-ref-rem-btn img {
  /* top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, 0%) !important; */
}

.cr-ref .cr-ref-btn.disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.cr-ref .cr-ref-btn.disabled:hover,
.cr-ref .cr-ref-btn.disabled:focus,
.cr-ref .cr-ref-btn.disabled:active {
  background-color: #ddd;
}

.create-btn {
  font-size: 1rem;
  font-family: "Nexa";
  font-weight: 700;
}

.react-datepicker-popper {
  z-index: 999 !important;
}

.react-datepicker {
  font-size: 0.7rem !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  font-size: 0.8rem !important;
}

.select__value-container {
  box-sizing: border-box;
}

.select__input-container {
  box-sizing: border-box;
  margin: -0.125rem !important;
}

.react-datepicker__input-container input {
  border: 0.0625rem solid #ddd !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.css-ls4h2u-ValueContainer {
  padding-left: 1.55rem !important;
}

.css-10vvmws-placeholder,
.css-1lspzq4-singleValue {
  margin-top: -3px !important;
}

/* End of Create Referendum Page */

.msgs-container {
  max-height: calc(100vh - var(--header-height) + var(--footer-height) - 12.5rem);
  overflow: hidden scroll;
}

.OPcard-rounded {
  border-radius: 1.25rem;
}

.quick-message-container {
  position: absolute;
  left: -31%;
  background-color: white;
  padding: 0.625rem;
  width: 21.25rem;
  border: 0.0625rem solid var(--color-1);
  border-radius: 0.9375rem;
  min-height: 26rem;
  z-index: 5;
}

.message-quick-box {
  max-height: 21.25rem;
  overflow-y: scroll;
}

.inbox-message {
  cursor: pointer;
  padding-bottom: 0.625rem;
}

.inbox-message:not(>.text-error):hover {
  background-color: #eeeeee;
}

/* Notification */
.op-notification>div,
.op-notif {
  padding: 1rem;
  border-radius: 1.875rem;
}

.op-notification-danger>div {
  border: 0.125rem solid var(--color-red);
  margin-bottom: 1rem;
}

.op-notification .Toastify__close-button>svg {
  height: 21px;
  width: 22px
}

.op-notification * {
  font-family: "Nexa";
  font-weight: 700;
  font-size: 0.8rem;
  color: #000;
}


.op-notif.default {
  border: 0.125rem solid var(--color-1);
}

.op-notif .Toastify__toast-body * {
  padding: 0 0 0 0;
}

.op-notif p {
  color: #000;
}

.op-notif a {
  color: var(--color-1) !important;
}

.Toastify__toast-container--bottom-right {
  bottom: 1rem;
  right: calc(1rem * 1.3);
}

.op-notif .notif-exit-btn {
  display: inline-block;
  background-color: transparent;
  width: 2.1875rem;
  height: 1.875rem;
  border: none;
  outline: none;
}

.op-notif .notif-exit-btn img {
  width: 100%;
  height: 100%;
}

/* End of Notification */
.OPAccordionItem,
.OPAccordionItem .accordion-button {
  background: transparent;
  border: 0;
  font-size: 1rem;
  padding: 0.375rem 0;
  color: var(--color-1);
  box-shadow: unset;
}

.OPAccordionItem .accordion-button:focus {
  box-shadow: none;
}

.OPAccordionItem .accordion-body {
  font-size: 0.875rem;
  padding: 0.9375rem 0;
  color: var(--gray);
}

.OPinput-group input,
.OPinput-group textarea {
  border: 0.0625rem solid var(--gray-light);
  height: 1.875rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.OPinput-group label {
  font-size: 0.75rem;
  color: #c0bebe;
}

.contact-us-form button {
  background-color: var(--color-1);
  border: 0;
  padding: 0.75rem 2.3125rem;
  box-shadow: 0.1875rem 0.1875rem 0.3125rem #ccc;
}

.contact-us-form button:hover {
  background-color: var(--color-light-3);
}

.contact-us-form {
  /* width: 28.125rem; */
  width: 100%;
  margin: auto;
}

.lds-dual-ring {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
}

.lds-dual-ring.small {
  width: 1.25rem;
  height: 1.25rem;
}

.lds-dual-ring.xtrasmall {
  width: 3.25rem;
  height: 1.25rem;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 1.875rem;
  height: 1.875rem;
  margin: 0.5rem;
  border-radius: 50%;
  border: 0.375rem solid var(--color-1);
  border-color: var(--color-1) transparent var(--color-1) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring.xtrasmall:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin: 0.5rem;
  border-radius: 50%;
  border: 3px solid var(--color-1);
  border-color: var(--color-1) transparent var(--color-1) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring.med {
  width: 5rem;
  height: 5.625rem;
}

.lds-dual-ring.med::after {
  width: 3.75rem;
  height: 3.75rem;
  border: 0.625rem solid var(--color-1);
  border-color: var(--color-1) transparent var(--color-1) transparent;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.profile-card {
  border-radius: 1.5625rem;

}

.profile_picture {
  font-size: 7.8125rem;
  color: #000;
  border: 0.0625rem solid black;
  position: relative;
  overflow: hidden;
  height: 9.375rem;
  width: 9.375rem;
  border-radius: 50%;
}

.profile_picture.active {
  border: none;
}

.profile_picture svg {
  position: absolute;
  bottom: -10%;
}

.border-red {
  border: 0.0625rem solid red !important;
}

/* Reset Password */
.rp-title {
  font-family: "Nexa" !important;
  font-weight: 700;
}

/* End of Reset Password */
.profile-verified-check {
  position: absolute;
  font-size: 1.625rem;
  color: white;
  background: #3fa778;
  border-radius: 50%;
  width: 2.1875rem;
  height: 2.1875rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  bottom: -0.125rem;
  right: 0;
}

.success-check-verified-otp {
  font-size: 3.4375rem;
  width: 6.875rem;
  height: 6.875rem;
  background: #3fa778;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  display: flex;
}

.dropping-container {
  width: 23.75rem;
  max-width: 100%;
  justify-content: center;
  /* min-height: 25rem; */
  position: absolute;
  overflow: hidden;
  margin: auto;
  /* top: -2.0625rem; */
  bottom: 5rem;
  left: 50%;
  transform: translateX(-50%);
}

.dropping-container .card {
  min-height: 18.75rem;
  max-height: 18.75rem;
  min-width: 18.75rem;
  max-width: 18.75rem;
  border-radius: 1.875rem;
  margin-bottom: 1.3rem;
  margin-top: 1rem;
}

.outer-dropping-paper {
  /* margin-top: 5.3125rem; */
  overflow-y: hidden;
  /* padding-top: 0.9375rem; */
  overflow-x: hidden;
  z-index: 5;
  position: relative;
}

.inner-dropping-paper {
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0.0625rem 0.0625rem 0.3125rem #ccc;
  display: flex;
  min-height: 15.625rem;
  justify-content: center;
  width: 90%;
  position: relative;
  z-index: 1;
  margin: auto;
  border-radius: 1.875rem;
  padding: 0.9375rem;
}

.dropping-hole {
  height: 2.625rem;
  width: 100%;
  background-color: #ccc;
  border-radius: 3.125rem;
  position: absolute;
  z-index: 0;
  bottom: 0;
}

.dropping-half {
  height: 1.3125rem;
  width: 100%;
  background-color: #ccc;
  border-radius: 0 0 3.125rem 3.125rem;
  position: absolute;
  z-index: 2;
  bottom: 0;
}

.zindex1 {
  z-index: 1;
}

.sidebar button.mobile-close {
  display: none;
  padding: 0;
}

.drop-box-bg {
  position: relative;
  bottom: 0;
  width: 39.8125rem;
  margin: auto;
  left: 0;
  right: 0;
  bottom: -0.1875rem;
}

.cut-image {
  height: 14rem;
  overflow: hidden !important;
  position: relative;
}

.overflow-hidden {
  overflow-y: hidden;
}

.hamburger {
  font-size: 30px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hamburger:hover {
  cursor: pointer;
}

.ref-view-container {
  border-radius: 1.875rem;
  padding-top: 1.875rem;
}

.ref-view-container.ref {
  margin-bottom: 3.125rem;
}

.ref-view-main-content {
  /* max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 0.625rem + 1.875rem + 20px)); */
  /* min-height: 18.75rem; */
  min-width: 100%;
  /* padding-bottom: 1.875rem; */
}

.ref-res-bd {
  min-height: 100%;
}

.ld-main-content {
  /* max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 30px)); */
  max-height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.ld-row {
  max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 30px));
  box-sizing: border-box;
  overflow: hidden visible;
}

.ld-header>h6 {
  display: none;
}

.ld-footer-rank {
  display: block;
}

.msgs-container {
  color: black;
}

.dt-header,
.dt-text {
  flex: 0 0 10rem;
}

.search-header {
  display: grid;
  align-items: center;
  grid-template-columns: calc(100% - 15.125rem - 16.875rem) 15.125rem 16.875rem;
  grid-template-areas: "search-header search-action search-key-filter";
  grid-template-rows: auto;
}

.search-header.my-follows {
  grid-template-columns: calc(100% - 16.875rem) 16.875rem;
  grid-template-areas: "search-header search-key-filter";
}

.search-header:has(> div:nth-child(2) > .exp-category) {
  /* grid-template-columns: calc(100% - 20.125rem - 16.875rem) auto 16.875rem; */
  grid-template-columns: auto auto 16.875rem;
}

.search-header>div:nth-child(1) {
  grid-area: search-header;
  justify-self: flex-start;
}

.search-header>div:nth-child(2) {
  grid-area: search-action;
  justify-self: flex-end;
}

.search-header>div:nth-child(3) {
  grid-area: search-key-filter;
  justify-self: flex-end;
}

.search-header.my-follows>div:nth-child(2) {
  grid-area: search-key-filter;
  justify-self: flex-end;
}

.input-ctr {
  position: absolute;
  font-family: "Nexa";
  font-weight: 300;
  font-size: 0.65rem;
  color: #ccc;
}

.input-ctr-bottom {
  bottom: 0.625rem;
  right: 0.625rem;
}

.input-ctr-center {
  right: 3.125rem;
  text-align: center;
  top: 50%;
  transform: translateY(-60%);
}

.sidebar h4>span>svg {
  display: none;
}

.badge-mobile {
  background-color: var(--color-red) !important;
  color: white;
  border-radius: 50%;
  font-size: 7px;
  display: flex;

  align-items: center;
  margin-left: 8px;
  min-width: 20px;
  min-height: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 4px;
}

.login_register_container {
  width: 100%;
  max-width: 54.6875rem;
  max-height: var(--login-register-container-height);
}

.login_register_container .login-register {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-us-content {
  min-height: calc(100vh - (var(--header-height) + var(--footer-height) + 4rem));
  max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 4rem));
  overflow: hidden;
}

.cu-form-right {
  display: block;
}

.cu-form-left {
  display: none;
}

.cu-left {
  max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 13rem));
  overflow: hidden scroll;
}

.cu-img-container {
  background-image: url("./assets/images/contact-us/contact-us.svg");
  background-repeat: no-repeat;
  background-position-x: center;
}

.twoFA-input-profile::-webkit-outer-spin-button,
.twoFA-input-profile::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.twoFA-input-profile[type=number] {
  -moz-appearance: textfield;
}

/* .customizeSelect .select__indicators {
  display: none;
} */

.customizeSelect .select__menu {
  box-shadow: none;
  border: 1px solid #ddd;
}

.ref_create_select .select__menu {
  z-index: 999;
}

#ed-root:has(div.main.reset-logout) {
  max-width: 100vw;
  box-sizing: border-box;
  grid-template-columns: 17.1875rem 1fr;
  grid-template-rows: var(--header-height) 1fr;
  grid-template-areas:
    "header header"
    "main main"
    "footer footer";
}

.main.reset-logout {
  height: calc(100vh - (var(--header-height) + var(--footer-height))) !important;
}

.main.reset-logout .center {
  width: 100%;
  margin: 0 auto;
}

.OPTable.table>:not(caption)>*>* {
  background-color: transparent !important;
}

.OPTable.table tr td:first-child {
  text-wrap: nowrap;
  width: 20%;
}

.sidebar-text svg {

  display: none;
}

.MobileMenu {
  display: none;
}

textarea#report {
  border: 1px solid #ddd;
}


.op-back-btn {
  width: 7.5rem;
  background-color: #fff;
  border: 0.1875rem solid #ddd;
  color: #888;
  outline: none;
}

.op-back-btn:hover,
.op-back-btn:active {
  background-color: #ddd;
  border: 0.1875rem solid #ddd;
  color: #fff;
}


.retry-btn {
  background-color: #eee;
  color: #888;
}

.retry-btn:hover {
  background-color: #ddd;
  color: #888;
}

/* crop css */
.crop-container {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #ffffffa8;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crop-container .inner {

  width: 29.375rem;
  padding: 2.5625rem;
  min-height: 400px;
  background: white;
  max-width: 100%;
  box-shadow: 0.1875rem 0.0625rem 0.5rem #ccc;
  border-radius: 1.875rem;
  position: relative;
  text-align: center;
}

.crop-container h4 {
  font-size: 24px;
  font-family: "Nexa";
  font-weight: 700;
  color: black;
}

.crop-container .close {
  position: absolute;
  width: 1.375rem;
  right: 1.75rem;
  top: 1.875rem;
  cursor: pointer;
}

.crop-area {
  height: 23.125rem;
  width: 18.75rem;
  background-color: #ccc;
  max-width: 100%;
  margin: auto;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
}

.reactEasyCrop_CropAreaGrid::after,
.reactEasyCrop_CropAreaGrid::before {
  border: 0px !important;
}

.suggestions-main-container {
  /* max-height: calc(100vh - (var(--footer-height) + var(--header-height))); */
}

.suggestions-main-container .row .col-12 {
  /* max-height: calc((100vh - (var(--footer-height) + var(--header-height))) / 2.2);
  overflow: hidden; */
}

.suggestions-list.small {
  height: 100%;
  overflow-y: scroll;
  max-height: calc((100vh - (var(--footer-height) + var(--header-height))) / 2.8);
}

.suggestions-list.large {
  height: 100%;
  overflow-y: scroll;
  max-height: calc((100vh - (var(--footer-height) + var(--header-height))) / 1.4);
}


.ref-main-container {
  width: 75%;
}

.op-box {
  box-shadow: rgba(14, 30, 37, 0.12) -1px -1px 4px 0px, rgba(14, 30, 37, 0.12) 5px 5px 5px 0px, rgba(14, 30, 37, 0.12) 5px 5px 10px 0px !important;
  -webkit-box-shadow: rrgba(14, 30, 37, 0.12) -1px -1px 4px 0px, rgba(14, 30, 37, 0.12) 5px 5px 5px 0px, rgba(14, 30, 37, 0.12) 5px 5px 10px 0px !important;
  -moz-box-shadow: rgba(14, 30, 37, 0.12) -1px -1px 4px 0px, rgba(14, 30, 37, 0.12) 5px 5px 5px 0px, rgba(14, 30, 37, 0.12) 5px 5px 10px 0px !important;
}

.sort-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
}

.sort-active {
  color: var(--color-1)
}

.sort-btn:hover {
  color: var(--color-light-4);
}

.sort-btn img {
  width: 10px;
}

.sort-btn:hover img {
  filter: opacity(70%);
}

.sort-btn.sort-desc img {
  transform: rotate(0deg);
}

.sort-btn.sort-asc img {
  transform: rotate(180deg);
}

.sort-btn.sort-disabled {
  cursor: not-allowed;
  color: gray;
}

.sort-btn.sort-disabled img,
.sort-btn.sort-disabled:hover img {
  filter: grayscale(100%);
}

.sort-btn.sort-active.sort-disabled {
  color: var(--color-light-4);
}

.sort-btn.sort-active.sort-disabled img {
  filter: opacity(70%);
}

.mobile-btn {
  display: none;
}

.ref-publish {
  min-height: "18.75rem";
  min-width: "100%";
  border-radius: "1.875rem";
  padding-top: "1.875rem";
  padding-bottom: "1.25rem";
  height: "auto";
}

.mz-scroll,
.select__menu-list {
  scrollbar-width: thin;
  scrollbar-color: #ddd rgba(0, 0, 0, 0);
}

.mz-scroll-none {
  scrollbar-width: none;
}

.about-us-content {
  max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 4rem));
  overflow: hidden scroll;

}

.unsave-modal {
  position: fixed;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.651);
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.unsave-modal .modal-dialog {
  max-width: 100%;
}

.unsave-modal .close {
  position: absolute;
  width: 1.875rem;
  right: 1.125rem;
  top: 1.1875rem;
  opacity: 0.8;
  cursor: pointer;

}

.unsave-modal .close:hover {
  opacity: 1;
}

.unsave-modal .unsave-details {
  width: 800px;
  padding: 40px;
  border-radius: 16px !important;
  max-width: 100%;
  position: relative;
  z-index: 1000;
  background-color: white;
  box-shadow: 1px 1px 5px #6c6c6c;
}

.unsave-details .triangle,
.unsave-details .cicle {
  display: flex;
  justify-content: center;
}

.unsave-details .triangle img,
.unsave-details .circle img {
  width: 10.125rem;
  max-width: 100%;
}

.unsave-details .circle img {
  width: 8.125rem;
}

.unsave-details .circle div svg {
  color: white;
}

.unsave-details button {
  padding: 8px 32px;
  background-color: #66a3c4;
  color: white;
  border: 0px;
  margin-right: 20px;
  width: 127px;
  border-radius: 11px;
  text-align: center;
}

.unsave-details button.leave {
  background-color: transparent;
  color: #e7e7e7;
  border: 1px solid #e7e7e7;
}

.unsave-details button:hover {
  background-color: #08425d;
}

.unsave-details button.leave:hover {
  background-color: #a5a5a5;
}

.unsave-details button:last-child {
  margin-right: 0px;
}

.error-page-container {
  margin-left: 8.3333%;
  align-items: center;
}

.bg-img-error-404 {
  background-image: url("./assets/images/others/clueless-couple-404.svg"), url("./assets/images/home/background.jpg") !important;
  background-size: 35%, 90%;
  background-repeat: no-repeat, no-repeat;
  background-position: 95% 101%, bottom right;
}


.error-code {
  font-family: "Nexa";
  font-weight: 900;
  font-size: 17rem;
  line-height: 10rem;
  margin-bottom: 3rem;
}

.error-msg {
  font-size: 1.3rem;
  margin-bottom: 5rem;
}

.error-code,
.error-msg {
  color: #2C2C2C;
}

#home-btn {
  font-family: "Nexa";
  font-weight: 700;
}

/* #ed-root.mz-ref-view .footer {
  display: none;
}

#ed-root.mz-ref-view .footer.inside-footer {
  display: flex;
  position: relative;
  bottom: 0;
  left: -3.525rem;
}

#ed-root.mz-ref-view .dropping-container-parent {
  position: absolute !important;
  bottom: 0;
}

#ed-root.bg-img-error-404.mz-ref-view .footer {
  display: flex;
} */


#ed-root.mz .footer {
  display: flex;
}

#ed-root.mz .footer.inside-footer {
  display: none;
}


.my-ref-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

textarea[name=enquiry] {
  resize: none;
  height: auto !important;
}

/* Consent */

/* 
#consent-root .OPModalBox {
  width: 50vw !important;
  height: auto !important;
}

#consent-root h3 {
  font-size: 1.3rem;
}

#consent-root .consent-content {
  text-align: justify;
  max-height: 50vh;
  overflow-y: scroll;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

#consent-root .consent-content>* {
  font-family: "Nexa";
  font-weight: 300;
  font-size: 0.9rem;
  color: #000;
  margin: 0.8rem 0;
}

#consent-root .agree-btn,
#consent-root .disagree-btn {
  font-family: "Nexa";
  font-weight: 700;
  font-style: bold;
  font-size: 0.7rem;
}

#consent-root .disagree-btn {
  background-color: #fff;
  border: 0.1875rem solid #ddd;
  color: #888;
  outline: none;
  padding: 0.31rem 0;
}

#consent-root .disagree-btn:hover,
#consent-root .disagree-btn:active {
  background-color: #ddd;
  color: #fff;
} */



#consent-root {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--color-light-5);
}

#consent-root .consent-container {
  display: flex;
  padding: 0.8rem 0;
  width: 80%;
  margin: 0 auto;
  
  font-size: 0.865rem !important;
}

#consent-root .consent-info {
  flex: 2;
  padding-right: 1rem;
  display: flex;
  align-items: center;
}

#consent-root .consent-info p {
  color: #fff;
  margin: 0;
  font-family: "Nexa";
  font-weight: 300;
  font-size: 0.865rem !important;
}

#consent-root .consent-info a {
  color: #fff;
  text-decoration: underline;
}

#consent-root .consent-agreement {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

#consent-root .consent-agreement button {
  font-size: 0.865rem !important;
}

#consent-root .accept-btn,
#consent-root .decline-btn {
  font-family: "Nexa";
  font-weight: 500;
  font-size: 0.7rem;
}

#consent-root .accept-btn {
  background-color: var(--color-light-1);
  border: 1px solid var(--color-light-5);
  border-radius: 2rem;
}
#consent-root .accept-btn:hover,
#consent-root .accept-btn:active {
  background-color: var(--color-1);
  color: #fff;
}

#consent-root .decline-btn {
  background-color: var(--color-light-5);
  border: 1px solid var(--color-light-5);
  border-radius: 2rem;
  color: #fff;
  text-decoration: underline;
}


/* End of Consent */

@media screen and (max-width: 1200px) {
  .main-content {
    overflow: hidden scroll;
  }

  /* .suggestion-container,
  .suggestion-container.sm {
    min-height: 100%;
    height: 100%;
  }

  .no-ref-suggested {
    height: auto;
  } */
}

@media screen and (max-width: 1000px) {
  :root {
    --login-register-container-height: calc(100vh - 4rem);
    --opmodal-box-width: calc(100vw - 4rem);
  }

  #consent-root .OPModalBox {
    width: 100% !important;
    max-width: 95%;
  }

  .bg-img {
    background-image: url("./assets/images/home/background-mobile.jpg");
    background-size: 60%;
  }

  #ed-root {
    overflow: hidden;
  }

  #ed-root.mz {
    overflow: unset;
    grid-template-rows: 1fr;
  }

  .MobileMenu {
    display: block;
  }

  .sidebar-top-option {
    width: 100%;
    padding-top: 0.9375rem;
  }

  .sidebar-text svg {
    margin-left: 0.625rem;
    display: inline-block;
  }

  .sidebar ul.cat-list {
    overflow: auto;
    height: auto;
  }

  .ref-ended:has(.ref-active) {
    font-size: 0.96rem;
    position: relative;
    top: -0.2rem;
    margin-left: auto;
  }

  .sidebar-text.close svg {
    transform: rotate(-90deg);
  }

  .ref-active {
    position: relative;
    top: 0.15rem;
  }

  .ref-res-title {
    text-align: center;
  }

  .ref-res-status-votes {
    margin-right: 0.875rem;
  }

  .footer {
    /* margin-top: 10px; */
    width: 100%;
    justify-content: center;
  }

  .main-content {
    overflow: unset;
  }

  .main-content:has(.ref-view-main-content) {
    min-height: calc(100vh - (var(--header-height)));
    max-height: calc(100vh - (var(--header-height)));
  }

  .main-content:has(.ref-reason),
  .main-content:has(.past) {
    box-sizing: border-box;
    min-height: calc(100vh - (var(--header-height) + var(--footer-height)));
    max-height: calc(100vh - (var(--header-height) + var(--footer-height)));
  }

  .main-content:has(.ref-reason)+.footer,
  .main-content:has(.past)+.footer {
    position: unset;
  }

  .main-content:has(.ref-sub-reason)+.footer {
    display: none;
  }

  .ref-view-main-content {
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 0.625rem + 1.875rem + 20px));
    box-sizing: border-box;
    overflow: hidden scroll !important;
    display: block !important;
  }

  .contact-us-content {
    overflow: hidden scroll;
  }

  .contact-us-content h5 {
    text-align: center;
  }

  .cu-left {
    max-height: 100%;
  }

  .cu-form-left {
    display: block;
  }

  /* .cu-form-right {
    display: none;
  } */



  .cu-img-container {
    width: 100%;
    height: 20rem;
  }

  .contact-us-content::-webkit-scrollbar,
  .review .sc-flag::-webkit-scrollbar,
  .login_register_container .login-register::-webkit-scrollbar,
  .ref-view-main-content::-webkit-scrollbar,
  .main .center.ref-main::-webkit-scrollbar {
    width: 0.375rem !important;
  }

  /* Track */
  .contact-us-content::-webkit-scrollbar-track,
  .review .sc-flag::-webkit-scrollbar-track,
  .login_register_container .login-register::-webkit-scrollbar-track,
  .ref-view-main-content::-webkit-scrollbar-track,
  .main .center.ref-main::-webkit-scrollbar-track {
    background: none;
  }

  /* Handle */
  .contact-us-content::-webkit-scrollbar-thumb,
  .review .sc-flag::-webkit-scrollbar-thumb,
  .login_register_container .login-register::-webkit-scrollbar-thumb,
  .ref-view-main-content::-webkit-scrollbar-thumb,
  .main .center.ref-main::-webkit-scrollbar-thumb {
    background: #ddd;
    border: 0.0625rem solid transparent;
    border-radius: 0.1875rem;
    background-clip: padding-box;
  }

  /* Handle on hover */
  .contact-us-content::-webkit-scrollbar-thumb:hover,
  .review .sc-flag::-webkit-scrollbar-thumb:hover,
  .login_register_container .login-register::-webkit-scrollbar-thumb:hover,
  .ref-view-main-content::-webkit-scrollbar-thumb:hover,
  .main .center.ref-main::-webkit-scrollbar-thumb {
    background: #ccc;
    border: 0.0625rem solid transparent;
    border-radius: 0.1875rem;
    background-clip: padding-box;
  }

  .ld-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ld-header>h5 {
    font-family: "Nexa";
    font-weight: 900;
  }

  .ld-header>h6 {
    display: flex;
    font-family: "Nexa";
    font-weight: 800;
  }

  /* .ld-main-content {
    max-height: calc(
      100vh - (var(--header-height) + var(--footer-height) + 20px)
    );
  } */

  .ld-row {
    /* max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 5.5rem)); */
    max-height: 100%;
    box-sizing: border-box;
    overflow: hidden scroll;
  }

  .about-us-content {
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 4rem));
    box-sizing: border-box;
    overflow: hidden scroll;
  }

  .about-us-content div:has(img) {
    justify-content: center !important;
  }

  .about-us-content img {
    width: 80%;
    height: auto;
    margin: 0 auto;
  }

  .OPTable thead {
    display: none !important;
  }

  .OPTable tbody td {
    display: block !important;
    width: 100% !important;
    font-family: "Nexa" !important;
  }

  .OPTable tbody td:first-child {
    font-size: 0.7rem;
    padding: 0.7rem 2rem 0.5rem 2rem;
  }

  .OPTable tbody td:nth-child(2) {
    font-size: 0.965rem;
    padding: 0.5rem 2rem 0.7rem 2rem;
  }

  .OPTable tbody tr {
    border-bottom: 1px solid var(--gray-light);
  }

  .OPTable tbody tr:last-child {
    border-bottom: none;
  }

  .ld-footer-rank {
    display: none;
  }

  .OPModalBox {
    width: 100%;
    max-width: var(--opmodal-box-width);
  }

  .exit-btn {
    right: 0.75rem;
    top: 0.875rem;
  }

  .login_register_container,
  .review {
    max-height: var(--login-register-container-height);
    overflow: hidden;
    flex-direction: column;
  }

  .login_register_container .login-register,
  .review .sc-flag {
    overflow: hidden scroll;
    max-height: calc(var(--login-register-container-height) - 10rem);
  }

  .login-register input[type="text"],
  .login-register input[type="email"],
  .login-register input[type="password"] {
    width: 100%;
  }

  .login-register input[type="text"]:focus,
  .login-register input[type="email"]:focus,
  .login-register input[type="password"]:focus {
    width: 100%;
  }

  .border-left-divider {
    border-left: none;
  }

  .border-bottom-divider {
    border-bottom: 0.0625rem solid #ddd;
  }

  .exit-container {
    width: 1rem;
    height: 1rem;
  }

  .auth-container,
  .welcome {
    height: auto;
  }

  .ref-categories ul {
    top: -1.26rem;
  }

  .sc-flag form {
    align-items: start !important;
  }

  .sc-flag form div:has(.op-btn) {
    margin: 0 auto;
  }

  .dropping-container {
    max-width: 75%;
    bottom: 3rem;
  }

  /* .dropping-hole {
    width: 100%;
  }

  .dropping-half {
    width: 100%;
  } */

  .right-nav .search-form input {
    width: 3rem;
    padding-left: 1.5rem;
    border: 0.125rem solid #eee !important;
  }

  .right-nav .search-form input:hover {
    cursor: pointer;
  }

  .right-nav .search-form.active input {
    width: 3rem;
    padding-left: 1.5rem;
  }

  .right-nav .search-form.active input:focus,
  .right-nav .search-form.active input:active {
    border: 0.125rem solid #eee !important;
  }

  .right-nav .search-form.active .icon {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1.15rem;
    height: 1.15rem;
  }

  .search-container {
    width: calc(100vw - 3rem);
  }

  .search-container:has(.search-header.my-follows) {
    width: 100% !important;
    margin-top: 10px;
  }

  .search-header.my-follows {
    display: block;
  }

  .suggestion-container {
    padding-right: 1rem;
  }

  .suggestion-container {
    padding-right: 1rem;
  }

  .ref-publish {
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 0.625rem + 1.875rem + 20px));
  }

  #ed-root:has(.ref-publish, .ref-create) {
    height: 100vh;
  }

  .main-content:has(.ref-publish, .ref-create)+.footer {
    position: relative;
    bottom: 0;
  }

  .contact-us-content {
    min-height: calc(100vh - (var(--header-height) + var(--footer-height) + 4rem));
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 4rem));
  }


  /* #ed-root.mz-ref-view .footer {
    display: block;
  }

  #ed-root.mz-ref-view .footer.inside-footer {
    display: none;
  }

  #ed-root.mz-ref-view .dropping-container-parent {
    position: relative !important;
    bottom: 0;
  } */


  .main-home>.center {
    padding-bottom: 1rem !important;
  }

  #ed-root.mz .footer.outside-footer {
    display: flex !important;
  }

  #ed-root.mz:has(.ref-create) .footer.outside-footer {
    display: none !important;
  }

  #ed-root.mz:has(.ref-publish) .footer.outside-footer {
    display: flex !important;
  }

  #ed-root.mz .footer.inside-footer {
    display: none !important;
  }

  #ed-root.mz .main-content {
    min-height: calc(100vh - (var(--header-height) + var(--footer-height))) !important;
  }

  /* #ed-root.mz .main-content:has(.ref-create) {
    min-height: calc(100vh - (var(--header-height))) !important;
  } */

  .ref-view-main-content {
    overflow: visible;
    max-height: initial;
    min-height: initial;
    height: initial;
  }

  #ed-root.mz-ref-view:not(.user-voted) .main-content {
    min-height: calc(100vh - (var(--header-height) + var(--footer-height)));
    height: initial;
    max-height: initial;
    overflow: visible;
  }

  #ed-root.mz-ref-view .footer.footer.outside-footer {
    display: flex !important;
    position: relative;
    top: unset;
  }

  #ed-root.user-voted .footer.footer.outside-footer {
    display: none !important;
  }

  /* .result-body {
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 15.4375rem)) !important;
  } */

  .main-content {
    scrollbar-width: none;
  }

  .op-notification-danger>div {
    border: 0.125rem solid var(--color-red);
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 1001px) {
  html {
    font-size: 13px;
  }
}

@media screen and (min-width: 1280px) {
  html {
    font-size: 15px;
  }
}

@media screen and (min-width: 1440px) {
  html {
    font-size: 13px;
  }
}

@media screen and (max-width: 1440px) {
  .right-nav .search-form.active {
    width: 14.25rem !important;
  }

  .ref-main-container {
    width: 95%;
  }


  .bg-img-error-404 {
    background-size: 40%, 100%;
  }


  .error-code {
    font-family: "Nexa";
    font-weight: 800;
    font-size: 13rem;
    line-height: 8rem;
    margin-bottom: 3rem;
  }

  .error-msg {
    font-size: 1.2rem;
    margin-bottom: 5rem;
  }
}



@media screen and (max-width: 1400px) {

  /* .result-body {
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 17.4375rem));
  } */

  #ed-root.mz .footer {
    display: none;
  }

  #ed-root.mz .footer.inside-footer {
    display: flex;
    position: relative;
    bottom: 0;
    left: -.025rem;
  }

  #ed-root.mz .main-content {
    min-height: calc(100vh - (var(--header-height)));
    max-height: calc(100vh - (var(--header-height)));
    overflow: hidden scroll !important;
    position: relative;
  }

  .main-home>.center {
    padding-bottom: 0;
  }

  .suggestion-container,
  .suggestion-container {
    min-height: 100%;
  }

  .no-ref-suggested {
    height: 5rem;
  }

  .ld-main-content {
    height: initial;
    max-height: initial;
    position: relative;
    overflow: visible;
  }

  .ld-row {
    height: initial;
    max-height: initial;
    overflow: visible;
  }


  .suggestions-list {
    overflow-y: visible !important;
    max-height: 100% !important;
  }
}


@media screen and (max-width: 1380px) {
  .right-nav .search-form.active {
    width: 13.25rem !important;
  }

  .ref-view-header * {
    /* flex-wrap: wrap; */
    font-size: 0.668rem !important;
  }

  .ref-view-header .ref-date {
    /* margin-bottom: 1rem;
    flex: 0 0 100%; */
  }

  .ref-view-header .ref-date {
    /* justify-content: space-between; */
  }

  /* .ref-view-header .ref-date:has(.ref-active) {
    justify-content: start;
  } */

  .ref-actions>button:first-child {
    /* margin: 0 0.8rem 0 0 !important; */
  }

  .ref-actions>button:first-child::after {
    padding-right: 1rem;
  }

  .ref-actions>button:nth-child(2) {
    /* margin: 0 0.1rem 0 0 !important; */
  }

  .ref-action .ref-action-menu.active {
    width: 11rem;
  }

  .ref-country {
    margin-left: 0 !important;
  }

  .menu-icon {
    margin-right: 0 !important;
  }
}


@media screen and (max-width: 1280px) {
  .right-nav .search-form.active {
    width: 14.25rem !important;
  }

  /* .result-body {
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 25.4375rem));
  } */

}

@media screen and (max-width: 1180px) {
  .right-nav .search-form.active {
    width: 12.25rem !important;
  }

  .ref-view-header * {
    font-size: 0.7rem !important;
  }
}

@media screen and (max-width: 1080px) {
  .right-nav .search-form.active {
    width: 10.25rem !important;
  }

  .ref-categories {
    z-index: 999;
  }

  /* 
  .ref-view-header .ref-date {
    margin-bottom: 1rem;
    flex: 0 0 100%;
  }

  .ref-view-header .ref-date {
    justify-content: space-between;
  }

  .ref-view-header .ref-date:has(.ref-active) {
    justify-content: flex-start;
  }

  .ref-cc, .ref-actions {
    margin-bottom: 1rem;
  }


  .ref-actions > button:first-child::after {
    content: none !important;
  }
  
  .ref-actions > button:nth-child(2)::after {
    content: none !important;
  }

  
  .ref-date ~ .ref-actions > button:nth-child(2)::after {
    content: none !important;
  }
  
  .ref-date ~ .ref-actions > button.active:nth-child(2)::after {
    content: none !important;
  }

  .ref-date:has(.ref-active) ~ .ref-actions > button:nth-child(2)::after {
    content: none !important;
  }

  .ref-date:has(.ref-active) ~ .ref-actions > button.active:nth-child(2)::after {
    content: none !important;
  } */
}

@media screen and (max-height: 1081px) {
  /* .result-body {
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 15.9375rem));
  } */
}

@media screen and (max-height: 1080px) {
  /* .result-body {
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 13.9375rem));
  } */
}

/* @media screen and (max-height: 1024px) {
  .result-body {
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 20.4375rem));
  }
} */

@media screen and (max-height: 930px) {
  /* .result-body {
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 23.4375rem));
  } */
}

@media screen and (max-height: 860px) {
  /* .result-body {
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 25.4375rem));
  } */
}


@media screen and (min-width: 1920px) {

  /* For Projectors or Higher Resolution Screens (Full HD) */
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 2120px) {

  /* For Projectors or Higher Resolution Screens (Full HD) */
  html {
    font-size: 18px;
  }

  /* .result-body {
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 15.4375rem));
  } */
}

@media screen and (min-width: 2560px) {

  /* For Projectors or Higher Resolution Screens (Full HD) */
  html {
    font-size: 21px;
  }
}

@media screen and (min-width: 3840px) {

  /* For 4K Displays (Ultra HD) */
  html {
    font-size: 30px;
  }

  /* .result-body {
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 15.4375rem));
  } */
}

@media screen and (min-width: 5000px) {

  /* For 4K Displays (Ultra HD) */
  html {
    font-size: 40px;
  }
}

@media screen and (min-width: 5840px) {

  /* For 4K Displays (Ultra HD) */
  html {
    font-size: 45px;
  }
}

@media screen and (min-width: 7680px) {

  /* For 8K Displays (Ultra HD) */
  html {
    font-size: 60px;
  }
}


@media screen and (max-height: 1024px) and (max-width: 1366px) {
  /* .result-body {
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 23.4375rem));
  } */
}

@media screen and (max-height: 1366px) and (max-width: 1024px) {
  /* .result-body {
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 55.4375rem));
  } */
}

@media screen and (max-height: 800px) and (max-width: 1366px) {
  /* .result-body {
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 15.4375rem));
  } */
}

@media screen and (max-height: 768px) and (max-width: 1024px) {
  /* .result-body {
    max-height: calc(100vh - (var(--header-height) + var(--footer-height) + 25.4375rem));
  } */
}

.Toastify__progress-bar-theme--light {
  background: #66a3c4;
}

@media print {

  * {
    -webkit-print-color-adjust: exact !important;
    /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;
    /* Firefox 48 – 96 */
    print-color-adjust: exact !important;
  }

  #ed-root {
    position: relative;
    display: grid;
    width: 100%;
    box-sizing: border-box;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      "main";
  }

  .bg-img {
    background-size: 100%;
  }

  .main-content {
    max-height: 100% !important;
    margin-bottom: 25px;
    width: 100%;
  }

  .sidebar,
  .header {
    display: none;
  }

  .footer {
    display: block;
  }

  .ref-main-container {
    width: 96%;
  }

}