#print-toggle-btn {
    background-color: #ccc;
    position: fixed;
    top: 50%;
    right: 1rem;
    border-radius: 50%;
    border: none;
    width: 3.125rem;
    height: 3.125rem;
    z-index: 999;
}

#print-toggle-btn:hover {
    background-color: var(--color-light-3);
}

#print-toggle-btn.active {
    background-color: var(--color-1);
}

#print-toggle-btn img {
  width: 1.563rem;
  height: 1.563rem;
}

#print-toggle * {
  font-family: "Nexa";
  font-weight: bold;
}

#print-toggle .print-setting-container {
    position: fixed;
    top: 50%;
    right: 1rem;
    transform: translateY(-105%) !important;
    text-align: end;
    background-color: rgba(255,255,255, 1);
    padding: 0 0.3125rem 0.4375rem 0.625rem;
    border: 0.063rem solid var(--color-1);
    border-radius: 0.625rem;
    z-index: 999;
    padding: 0.1875rem 0.8125rem 0.625rem 0.8125rem;
}

.show-btn {
  margin-top: 0.313rem;
  width: 3.75rem;
  height: 1.875rem;
  line-height: 0.625rem;
  border: none;
  border-radius: 0.3125rem;
  color: var(--white);
  background-color: var(--color-light-5);
  font-size: 0.75rem;
  user-select: none;
}

.show-btn:hover {
  color: var(--white);
  background-color: var(--color-1);
}

#print-toggle .radio-container,
#print-toggle .checkbox-container {
  margin: 0.125rem 0 0.188rem 0;
}

#print-toggle label {
  font-size: 0.8125rem;
  padding-left: 1.5625rem;
  user-select: none;
  cursor: pointer;
}

#print-toggle input[type="checkbox"],
#print-toggle input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

#print-toggle .checkbox-container .checkmark {
  position: absolute;
  top: 0.0625rem;
  left: 0;
  height: 1.125rem;
  width: 1.125rem;
  background-color: #fff;
  border: 0.0625rem solid var(--color-1);
  border-radius: 0.1875rem;
  margin-top: 0.125rem;
}

/* Create the checkmark/indicator (hidden when not checked) */
#print-toggle .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
#print-toggle input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
#print-toggle .checkbox-container .checkmark:after {
  content: "";
  left: 0.3125rem;
  top: 0.0625rem;
  width: 0.375rem;
  height: 0.75rem;
  border: 0.0625rem solid var(--color-1);
  border-width: 0 0.125rem 0.125rem 0;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}

#print-toggle .radio-container .checkmark {
  position: absolute;
  top: 0.1875rem;
  left: 0;
  height: 1.125rem;
  width: 1.125rem;
  background-color: #fff;
  border: 0.0625rem solid var(--color-1);
  border-radius: 50%;
}

#print-toggle .radio-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

#print-toggle .radio-container input:checked ~ .checkmark:after {
  display: block;
}

#print-toggle .radio-container .checkmark:after {
  top: 0.25rem;
  left: 0.25rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: var(--color-1);
}


