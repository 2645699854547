@media (max-width: 1500px) {
  .gp-header-text {
    font-size: 1.125rem;
  }

  /* .gp-ref-title {
    font-size: 0.75rem;
  } */
}

@media (max-width: 1300px) {
  .home-header-txt1 {
    font-size: 20px;
  }
}

@media (min-width: 1001px) {
  .hamburger {
    display: none;
  }
}

@media (max-width: 1000px) {

  .modal-search-container {
    width: calc(100vw - 3rem);
  }

  .center-nav {
    display: none;
  }

  #ed-root {
    display: flex;
    flex-wrap: wrap;
  }

  .home-header-txt1 {
    width: 100%;
    margin-bottom: 0.625rem;
  }

  .top.home form {
    box-sizing: border-box;
    width: 100%;
  }

  .top.home form input {
    width: 100%;
  }

  .header {
    width: 100%;
  }

  .logo-menu-mobile {
    display: block;
    width: 90px !important;
    height: auto !important;
  }

  .sidebar {
    position: absolute;
    z-index: 999;
    background-color: white;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s ease-in;
  }

  .sidebar.open {
    opacity: 1;
    pointer-events: all;
    transition: 0.1s ease-in;
  }

  .sidebar h4 {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    height: auto !important;
  }

  .sidebar h4>span {
    width: 100%;
    padding: 20px 0px 0px;
    display: flex;
    align-items: center;
  }

  .sidebar h4>span>svg {
    font-size: 20px;
    margin-left: 8px;
    transition: 0.1s ease-in;
    display: block;
  }

  .sidebar h4>span.close>svg {
    font-size: 20px;
    margin-left: 8px;
    transform: rotate(-90deg);
    transition: 0.1s ease-in-out;
  }

  .collapse-menu.close {
    height: 0;
    overflow: hidden;
    transition: 0.1s ease-in-out;
  }

  .sidebar button.mobile-close {
    display: inline-block;
    width: auto;
    padding: 0px;
    margin-left: auto;
    position: absolute;
    right: 30px;
    top: 32px;
  }

  .main-content {
    width: 100%;
  }

  .profile-card {
    min-height: 100%;
  }
}

@media (max-width: 500px) {
  .right-nav {
    padding-right: 0px;
  }

  .left-nav {
    padding-left: 15px;
  }

  .center-nav {
    display: none;
  }

  .header {
    padding: 15px;
  }
}

@media (max-width: 1300px) {
  .home-header-txt1 {
    font-size: 20px;
  }
}

@media (min-width: 1001px) {
  .hamburger {
    display: none;
  }
}

@media (max-width: 1000px) {
  .unsave-modal .unsave-details {
    max-width: 90%;
  }

  .contact-us-content .card-body .cu-left>p {
    text-align: center;
  }

  .sidebar-container-menu {
    overflow-y: scroll;
    height: calc(100vh - 15rem);
  }

  .center-nav {
    display: none;
  }

  #ed-root {
    display: flex;
    flex-wrap: wrap;
  }

  .home-header-txt1 {
    width: 100%;
    margin-bottom: 0.625rem;
  }

  .gp-ref {
    padding: 0.325rem 0.5rem;
  }

  .gp-ref-title {
    font-size: 1rem;
  }

  .header {
    width: 100%;
  }

  .logo-menu-mobile {
    display: block;
    width: 90px !important;
    height: auto !important;
  }

  .sidebar {
    position: absolute;
    z-index: 999;
    background-color: white;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s ease-in;
  }

  .sidebar.open {
    opacity: 1;
    pointer-events: all;
    transition: 0.1s ease-in;
    padding-top: 0.9375rem;
  }

  .sidebar h4 {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    height: auto !important;
    padding-bottom: 0rem;
  }

  .sidebar h4>span {
    width: 100%;
    padding: 20px 0px 0px;
    cursor: pointer;
  }

  .sidebar button.mobile-close {
    display: inline-block;
    width: auto;
    padding: 0px;
    margin-left: auto;
    position: absolute;
    right: 30px;
    top: 32px;
    background-color: transparent;
    border: none;
  }

  .sidebar button.mobile-close img {
    margin: 0 auto;
    width: 1.375rem;
    height: auto;
  }

  .main-content {
    width: 100%;
  }

  /* referendum header */

  #ed-root.mz .main-content {
    max-height: 100%;
  }

  .main-content:has(.main > .home),
  .main-content:has(.main.search),
  .main-content:has(.main.my-history),
  .main-content:has(.main.profile) {
    height: auto;
    max-height: 100%;
  }

  /* .suggestion-container,  */
  .suggestion-container.sm {
    min-height: auto;
    height: auto;
    max-height: auto;
  }

  .no-ref-suggested,
  .no-ref-suggested.lg {
    height: auto;
    padding: 1rem 0.5rem 0.5rem 0.5rem;
  }

  .search-container {
    padding: 2rem 0.5rem 2rem 1rem !important;
  }

  .result-container .result-header,
  .result-container .ref-author,
  .result-container .ref-user-vote,
  .result-container .dt-start-text,
  .result-container .dt-end-text {
    display: none;
  }

  .result-container .result-body .row {
    border-bottom: 1px solid var(--gray-light);
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin-top: 0 !important;
  }

  .result-container .result-body .row:last-child {
    border-bottom: none;
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin-top: 0 !important;
  }

  .result-container .result-body .row .search-ref-title {
    display: block;
    width: 100% !important;
    font-size: 1rem;
  }

  .result-container .result-body .row .result-text {
    display: flex;
    justify-content: flex-start;
    flex: 1 1 50%;
    padding: 0 !important;
    padding-left: 0.625rem !important;
  }

  .result-container .result-body .row .result-percentage {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    padding: 0 0.625rem 0 0 !important;

  }

  .search-header,
  .my-ref-header {
    padding-right: 1rem !important;
  }

  .gp-header-text {
    /* font-size: 1.4rem; */
  }

  .search-grouping {
    /* display: none; */
  }

  .search-header {
    flex-wrap: wrap;
  }

  .search-header {
    /* grid-template-columns: calc(100% - 16.875rem) 16.875rem; */
    grid-template-columns: auto 16.875rem;
    grid-template-areas:
      "search-header search-key-filter"
      "search-action search-action";
  }

  .search-header:has(> div:nth-child(2) > .exp-category) {
    grid-template-columns: auto 16.875rem;
    grid-template-areas:
      "search-header search-key-filter"
      "search-action search-action";
  }

  .search-header>div:nth-child(2) {
    order: 3;
    width: 100%;
    margin-top: 10px;
  }

  .search-header>div:nth-child(2)>div.exp-grouping {
    display: block !important;
    width: 100% !important;
    padding-right: 0 !important;
  }

  .search-header>div:nth-child(2)>div.exp-category {
    width: 100% !important;
    padding-right: 0 !important;
  }

  .search-header>div:nth-child(2)>div.exp-category>div {
    width: 50% !important;
    box-sizing: border-box;
  }

  .search-grouping {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
  }

  .search-grouping>li {
    width: 100%;
  }

  /* .history-search-input {
    width: 174px !important;
  } */

  .my-ref .result-body>.row>.col-1:last-child {
    width: auto;
  }

  .OPselectBox {
    min-width: 100%;
  }

  .result-container.my-ref .result-header>div,
  .result-container.my-ref .result-body>div.row>div {
    /* padding: 0.625rem 1.625rem 0.625rem 0.625rem; */
    padding: 0;
  }

  .ref-res-bd>div:last-child {
    padding-bottom: 2rem !important;
  }

  .ref-res-status-label {
    width: 63%;
  }

  .mobile-btn {
    display: block;
  }


  .header-right-action-menu {
    width: 9.275rem;
  }

  .header-right-action-menu li>* {
    padding: 0.35rem 0.3125rem;
  }

  .bg-img-error-404 {
    background-image: url("./assets/images/others/clueless-couple-404.svg"), url("./assets/images/home/background-mobile.jpg") !important;
    background-size: 50%, 60% !important;
    background-position: 95% 101%, bottom right;
  }

  .error-page-container {
    margin: 5rem 0 0 5rem;
    align-items: start;
  }

  #ed-root.bg-img-error-404 .main-content {
    min-height: calc(100vh - (var(--header-height))) !important;
    max-height: calc(100vh - (var(--header-height))) !important;
  }

  #ed-root.bg-img-error-404 .footer {
    display: none !important;
  }


  .ref-view-header {
    flex-wrap: wrap;
  }

  .ref-view-header .ref-date {
    margin-bottom: 1rem;
    flex: 0 0 100%;
  }

  .ref-view-header .ref-date {
    justify-content: space-between;
  }

  .ref-view-header .ref-date:has(.ref-active) {
    justify-content: flex-start;
  }

  .ref-actions>button:first-child {
    margin: 0 0.8rem 0 0 !important;
  }

  .ref-actions>button:first-child::after {
    content: none !important;
    padding-right: 1rem;
  }

  .ref-actions>button:nth-child(2) {
    margin: 0 0.1rem 0 0 !important;
  }

  .ref-actions>button:nth-child(2)::after {
    content: none !important;
  }

  .ref-actions>button.active:nth-child(2)::after {
    content: none !important;
  }

  .ref-date~.ref-actions>button:first-child::after {
    content: none !important;
  }

  .ref-date~.ref-actions>button:nth-child(2)::after {
    content: none !important;
  }

  .ref-date:has(.ref-active)~.ref-actions>button:nth-child(2)::after {
    content: none !important;
  }

  .ref-date~.ref-actions>button.active:nth-child(2)::after {
    content: none !important;
  }

  .ref-date:has(.ref-active)~.ref-actions>button.active:nth-child(2)::after {
    content: none !important;
  }
}

@media (max-width: 768px) {

  .result-container .result-body .row {
    padding: 0.5rem 1rem 0.5rem 1rem;
  }

  .suggestion-container {
    min-height: auto;
    height: auto;
    max-height: auto;
  }

  .no-ref-suggested.lg {
    height: auto;
    padding: 1rem 0.5rem 0.5rem 0.5rem;
  }

  .dropping-container .card {
    min-width: 16.75rem;
    max-width: 16.75rem;
    padding-top: 20px;
  }

  #modal_search::placeholder {
    font-size: 0.9rem;
  }

  .dropping-hole,
  .dropping-half {
    width: 90%;
    margin: auto;
    left: 0;
    right: 0;
  }

  .error-page-container {
    margin: 2rem 0 0 2rem;
  }

  #consent-root .consent-agreement {
    flex-direction: column;
  }

}

@media (max-width: 600px) {
  .search-header {
    grid-template-columns: 100%;
    grid-template-areas:
      "search-header search-header"
      "search-key-filter search-key-filter"
      "search-action search-action";
    padding-right: 0.5rem !important;
  }

  .search-header:has(> div:nth-child(2) > .exp-category) {
    grid-template-columns: 100%;
    grid-template-areas:
      "search-header search-header"
      "search-key-filter search-key-filter"
      "search-action search-action";
    padding-right: 0.5rem !important;
  }

  .search-header>div:nth-child(3) {
    margin-top: 5px;
    width: 100%;
  }

  .search-header>div:nth-child(3) *,
  .search-form .search-input-xs {
    width: 100%;
  }

  .my-ref-header {
    padding-right: 0 !important;
    flex-direction: column;
  }

  .my-ref-header>div:is(:nth-child(1), :nth-child(2)) {
    padding-right: 0.5rem !important;
  }

  .my-ref-header div:nth-child(2) * {
    margin-top: 2px;
    width: 100%;
  }

  .error-page-container {
    margin: 1rem 0 0 1rem;
  }

  /* .error-page-container .col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .error-page-container .col > *:not(.error-code) {
    align-self: flex-start;
  } */

  .error-code {
    font-family: "Nexa";
    font-weight: 800;
    font-size: 13rem;
    line-height: 10rem;
    margin-bottom: 1.5rem;
  }

  .error-msg {
    font-size: 1.3rem;
    margin-bottom: 5rem;
  }

  #home-btn {
    margin-top: 0 !important;
  }


}

@media (max-width: 500px) {
  .right-nav {
    padding-right: 0px;
  }

  .left-nav {
    padding-left: 15px;
  }

  .center-nav {
    display: none;
  }

  .header {
    padding: 15px;
  }

  #modal_search::placeholder {
    font-size: 0.8rem;
  }


  .result-container .result-body .row {
    padding: 0.5rem 1rem 0.5rem 1rem;
  }


  .error-page-container {
    margin: 1rem 0 0 0;
  }

  /* .error-page-container .col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .error-page-container .col > *:not(.error-code) {
    align-self: flex-start;
  } */
}

@media (max-width: 440px) {

  html {
    font-size: 12px;
  }

  .search-header .search-form .search-input-xs {
    padding: 0 1.5rem;
  }


  .error-page-container {
    margin: 1rem 0 0 0;
    padding: 0 10px 0 10px;
  }

  .error-page-container .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 25% !important; */
  }

  .error-page-container .col>*:not(.error-code) {
    align-self: flex-start;
  }

}

@media (max-width: 390px) {
  .dropping-container .card {
    min-width: 10.75rem;
    max-width: 14.75rem;
    padding-top: 20px;
  }

  html {
    font-size: 12px;
  }

  .gp-header-text {
    font-size: 1rem;
  }

  .search-header .search-form .search-input-xs {
    padding: 0 1.4rem;
  }

  .error-code {
    font-size: 11.5rem;
    margin-bottom: 1.2rem;
  }

  .error-msg {
    font-size: 1.3rem;
    margin-bottom: 3.5rem;
  }

}

@media (max-width: 360px) {

  html {
    font-size: 10px;
  }

  .search-header .search-form .search-input-xs {
    padding: 0 1.4rem;
  }

  #modal_search::placeholder {
    font-size: 0.65rem !important;
  }

  .top.home form input::placeholder {
    font-size: 0.85rem;
  }
}

/* Portrait */
@media (max-device-width: 667px) {
  html {
    font-size: 13px;
  }

  html:has(.ref-create) {
    font-size: 13px;
  }

  .main.ref-view-main {
    overflow-y: scroll;
  }

  .main .center {
    overflow-y: scroll;
  }

  .cut-image {
    height: 14rem;
    overflow: hidden !important;
    position: relative;

  }

  .drop-box-bg {
    bottom: -5.8125rem;
    position: relative;
  }
}

/* @media (max-width: 360px) {
  html {
    font-size: 12px;
  }
} */
@media (max-width:550px) {}

@media (max-width: 320px) {
  html {
    font-size: 8px;
  }

  .error-page-container {
    margin-top: 3rem;
    padding: 0 20px 0 20px
  }

  .error-code {
    font-size: 14.5rem;
    margin-bottom: 2rem;
  }

  .error-msg {
    font-size: 2rem;
    margin-bottom: 4rem;
  }

}




/* height Iphone SE*/

@media (max-height:670px) {}