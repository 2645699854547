.overflowscroll{

}
.cardP p{
   color:#161616;
   font-size: 0.875rem;
}
.maxHeight{
   max-height: calc(100vh - var(--header-height) - var(--footer-height) - 2rem);
}
.maxHeight>div{
   overflow-y: scroll;
   margin-top: 10px;
   margin-bottom: 10px;
}
.maxHeight>div::-webkit-scrollbar {
   width: 0.375rem;
 }
 .maxHeight>div::-webkit-scrollbar-track {
   background: none;
 }
 .maxHeight>div::-webkit-scrollbar-thumb {
   background: #ddd;
   border: 0.0625rem solid transparent;
   border-radius: 0.1875rem;
   background-clip: padding-box;
 }
 
.orderedList{
   counter-reset: item;
}
.orderedList>li>div{
   padding-bottom: 5px;
}
.orderedList li{
   display: block;
   position: relative;
}
.orderedList li:before {
   content: counters(item, ".")".";
   counter-increment: item;
   position: absolute;
   margin-right: 100%;
   right: 10px; /* space between number and text */
}
.letterList{
   counter-reset: alphabeticList;
}
.letterList li:before{
   content:"("counter(alphabeticList, lower-alpha)")";
   counter-increment: alphabeticList;
   position: absolute;
   margin-right: 100%;
   left: -25px; /* space between number and text */
   width: 25px;

}
.AlphaNumericList{
   counter-reset: numericList;
}
.AlphaNumericList li:before{
   content:"("counter(numericList, lower-roman)")";
   counter-increment: numericList;
   position: absolute;
   margin-right: 100%;
   left: -30px; /* space between number and text */
   width: 30px;

}
.b14{
   color: #161616;
   font-size: 0.875rem;
}
.az{
   color: #161616;
   font-size: 0.875rem;
   margin-top: 0.3125rem;
   
}
.b{
   font-family: Nexa;
   font-weight: 700;
   color: #161616;
}