#maintenance_container {
    position: relative;
    height: calc(100vh - var(--header-height));
    width: 100vw;
    background-image: url('../images/home/background.jpg');
    background-size: 90%;
    background-position: bottom right;
    background-repeat: no-repeat;
    overflow: hidden;
}

#maintenance_info_container {
    width: 100%;
    height: 100%;
    display: flex;
    padding-left: 13%;
    padding-top: 8rem;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    background-image: url('../images/others/system-under-maintenance.svg');
    background-size: 40%;
    background-position: bottom right 20%;
    background-repeat: no-repeat;
}

#maintenance_msg1,
#maintenance_msg2,
#maintenance_msg3 {
    font-family: "Nexa";
    color: #000;
}


#maintenance_msg1 {
    font-weight: 900;
    font-size: 6.5rem;
    line-height: 6.5rem;
    letter-spacing: 0.2rem;
}

#maintenance_msg2 {
    font-weight: 700;
    font-size: 2.1rem;
    line-height: 2.1rem;
}

#maintenance_msg3 {
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 1.8rem;
}

@media (max-width: 1300px) {

}

@media (max-width: 992px) {

    #maintenance_container {
        background-image: url('../images/home/background-mobile.jpg');
        background-size: 70% ;
    }
    
    #maintenance_info_container {
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 5rem;
        background-image: url('../images/others/system-under-maintenance.svg');
        background-size: 80%;
        background-position: bottom right 2rem;
        background-repeat: no-repeat;
    }

    #maintenance_msg1 {
        font-size: clamp(3rem, 10vw, 5rem);
        line-height: clamp(3.2rem, 10vw, 5rem);
        letter-spacing: 0.15rem;
    }
    
    #maintenance_msg2 {
        font-size: clamp(1.1rem, 10vw, 1.5rem);
        line-height: clamp(1.1rem, 10vw, 1.5rem);
    }
    
    #maintenance_msg3 {
        font-size: clamp(0.9rem, 10vw, 1.1rem);
        line-height: clamp(0.9rem, 10vw, 1.1rem);
    }
}

@media (max-width: 768px) {

    #maintenance_container {
        background-size: 80% ;
    }
}